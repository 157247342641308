import { NavLink, Outlet, useLoaderData, useResolvedPath, useMatch } from "react-router-dom";
import { useState, useEffect } from "react";
import { Tooltip } from 'react-tooltip'
import RatingsChartForThemes from "../components/RatingsChartForThemes";

const ThemesLayout = ({themesData}) => {
    const [averageRatings, setAverageRatings] = useState([]);
    const averageRatingsData = useLoaderData();

    const match = useMatch('/themes');
    const url = useResolvedPath("").pathname;

    // Calculate adjusted ratings when component mounts or themeRatingsData changes
    useEffect(() => {
        if (averageRatingsData.length > 0) {
        // Create a new array with both original and adjusted ratings
            const updatedRatings = averageRatingsData.map((object) => ({
                ...object, // the spread operator creates a copy of each original theme object within the array returned by the loader
                // this next bit of code adds the new property "adjusted_rating" to each copied copied object. 
                adjusted_rating: object.average_rating > 1000 // checks if the average_rating is greater than 1000
                ? Math.round(object.average_rating - 1000) // if it is we subtract 1000 from the average_rating
                : object.average_rating === 1000 // checks is the average_rating is exactly 1000
                    ? 1000 // if it is we make the adjusted_rating 1000
                    : Math.round(1000 - object.average_rating), // if it isn't (and therefore average_rating < 1000), we subtract the average_rating from 1000
            }));

        setAverageRatings(updatedRatings);
        console.log("New array", averageRatings)
        }
    }, [averageRatingsData]);
    

    return ( 
        <div>
            {match && (
            <>
            <h1 className="p-10 text-center text-4xl font-bold">Explore Themes</h1>
            <div className="grid grid-cols-6 gap-y-4 place-items-center pb-4">
                {averageRatings
                .map((object, i) => (
                    <NavLink key={i} className="border-2 rounded-lg flex flex-col justify-center items-center h-60 w-60" to={`${url}/${object.theme_url}`}> 
                        <div className={`h-36 w-44 flex justify-center text-center ${object.average_rating > 1000 ? "order-2 items-start" : "order-1 items-end"}`}>
                            <div className="border-y-2 h-12 w-44 flex justify-center items-center hover:bg-white hover:text-black transition-colors duration-500">
                                <p>{object.theme}</p>
                            </div>
                        </div>
                        <div className={`flex-grow flex flex-col items-center ${object.average_rating > 1000 ? "order-1 justify-end" : "order-2 justify-start"}`} data-tooltip-id="theme-tooltip" data-tooltip-content="Avg. Rating">
                            <div className={`w-10 bg-green-500 ${object.average_rating > 1000 ? "bg-green-400 order-2" : "bg-red-400 order-1"}`} style={{height: `${object.adjusted_rating}px`}}></div>
                            <div className={`${object.average_rating > 1000 ? "text-green-400 order-1" : "text-red-400 order-2"}`}>{Math.round(object.average_rating)}</div>
                        </div>
                        <Tooltip id="theme-tooltip" place={'left'}/>
                    </NavLink>
                ))}
            </div>
            {/*<RatingsChartForThemes chartData={averageRatings}/>*/}
            </>
            )}
            <Outlet />

        </div>
     );
}
 
export default ThemesLayout;

//loader function
export const averageRatingsLoader = async () => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const response = await fetch(`${apiUrl}/getAverageRatings.php`)
    return response.json()
}