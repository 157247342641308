import { useEffect, useState } from 'react';
import { useParams, useOutletContext } from 'react-router-dom';
import ChartForBlog2 from '../components/ChartForBlog2';
import ChartForBlog5 from '../components/ChartForBlog5';
import Chart2ForBlog5 from '../components/Chart2ForBlog5';

const BlogPage = () => {
    const { slug } = useParams();
    const imagePath = "/images/minifigures/blogs";
    const [blogs, setBlogs] = useOutletContext();

    const blogEntry = blogs.find((entry) => entry.slug === slug);

    if (!blogEntry) {
        // Handle case where the blog entry with the given slug is not found
        return (
          <div>
            <h1>Blog Entry Not Found</h1>
          </div>
        );
    }

    return (
        <div className='flex justify-center items-center'>
            <div className='lg:container px-4 lg:px-40 xl:px-60 py-10 flex flex-col gap-4'>
                <h1 className='text-center text-3xl font-bold'>{blogEntry.title}</h1>
                <div className='flex justify-center items-center text-center'>
                    <p className='text-green-400'>{blogEntry.date} by {blogEntry.author}</p>
                </div>

                <div className='flex justify-center items-center'>
                    <img src={`${imagePath}/${blogEntry.articleImg}`} alt={blogEntry.alt} className='h-auto w-auto object-contain'/>
                </div>
                
                <div className='text-justify'>
                {blogEntry.content.map((section, index) => {
                    if (section === '{CHART}') {
                        return <ChartForBlog2 key={index} />;
                    } else if (section === '{CHART2}') {
                        return <ChartForBlog5 key={index} />;
                    } else if (section === '{CHART3}') {
                        return <Chart2ForBlog5 key={index} />;
                    }
                    else {
                        return <div key={index}>{section}</div>;
                    }
                })}
                </div>
            </div>
        </div>
     );
}
 
export default BlogPage;
