import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

const RatingsChart = ({chartData}) => {
    const days = chartData.map((rating) => rating.rating_date);
    const data = chartData.map((rating) => rating.average_rating);

    const ratingChart = () => {
        return {
          labels: days,
          datasets: [
            {
            data: data,
            label: "",
            fill: "start",
            pointRadius: 0, // Change to 0 to hide points
            borderColor: "#4caf50",
            tension: 0.4, //The smooths the line between each point
          }
        ]
        };
      };

    const options = {
        plugins: {
          legend: {
            display: false, //This hides the legend at the top of the chart
          },
        },
        scales: {
          y: {
            title: {
              display: false, // Set display to true to show the y-axis label
              text: "Rating", // The label for the y-axis
            },
            ticks: {
              color: 'white', // Set the text color of y-axis ticks
            },
          },
          x: {
            ticks: {
              color: 'white', // Set the text color of x-axis ticks
            },
          }
        },
        indexAxis: 'x',
      };

    return ( 
    
            <div className='w-full flex flex-col justify-center items-center'>
                <h2 className="p-2 font-bold text-lg">Ratings History</h2>
                <div className='relative w-full p-0'>
                <Line data={ratingChart()} options={{...options, responsive: true}}/>
                </div>
            </div>
    
     );
}
 
export default RatingsChart;