import { NavLink, Outlet, useResolvedPath, useMatch } from "react-router-dom";
import { useState, useEffect } from "react";

const RankingLayout = ({themesData}) => {
    //Gets the path of the RankingLayout component (i.e. "/ranking"). This is used in the path associated with the NavLink element of each priority theme displayed in the RankingLayout component.
    const url = useResolvedPath("").pathname;

    //Gets the path of where the minifigure images are saved as stores them in the minifigureImagePath variable
    const minifigureImagePath = "/images/minifigures";
  
    const match = useMatch('/ranking');

    const [minifigureData, setMinifigureData] = useState([]);
    const [loading, setLoading] = useState(true);
    //State variable holding selected minfigure
    const [selectedMinifigure, setSelectedMinifigure] = useState(null);
    //State variable for controlling...
    const [showSelectedText, setShowSelectedText] = useState(false);
    // State variable for themes navigation banner
    const [priorityThemes, setPriorityThemes] = useState([]);
    const [ratingsChangeData, setRatingsChangeData] = useState("");

    // Determine the API base URL based on the environment
    const apiUrl = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
      const getPriorityThemes = () => {
        const themesWithPriorityOne = themesData.filter((theme) => theme.priority === '1');
        setPriorityThemes(themesWithPriorityOne);
      };
  
      if (themesData.length > 0) {
        getPriorityThemes();
      }
    }, [themesData]);
    
    const fetchMinifigures = async () => {
      try {
        const response = await fetch(`${apiUrl}/getMinifigures.php`);
        console.log("fetching");
        if (!response.ok) {
          throw Error("Error fetching minifigures");
        }
        const data = await response.json();
        setMinifigureData(data);
        setSelectedMinifigure(null);
      } catch (error) {
        console.error("Error fetching minifigures:", error);
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      // Fetch minifigures when the component mounts
      fetchMinifigures();
  
      // Cleanup function to cancel ongoing fetch when the component is unmounted
      return () => {
        // You can cancel ongoing requests here if necessary
      };
    }, []); // Empty dependency array ensures it only runs once when the component mounts

    // Function to handle the user's choice and update the ratings
  const handleUserChoice = (choice) => {
    // Make an HTTP POST request to update_ratings.php
    fetch(`${apiUrl}/update_elo_ratingsv4.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `choice=${choice}&itemA=${minifigureData[0].minifig_id}&itemB=${minifigureData[1].minifig_id}`,
    })
      .then((response) => response.json())
      .then((data) => {
        setSelectedMinifigure(choice);
        setShowSelectedText(true);
        setRatingsChangeData(data);
        setTimeout(() => {
          fetchMinifigures();
          //setSelectedMinifigure(null);
        }, 1000);
      })
      .catch((error) => {
        // Handle errors (if any)
        console.error("Error updating ratings:", error);
      });
  };

  const handleGenerateMatchUp = () => {
    fetchMinifigures();
  };

  return (
    <div className="bg-black">

      <div className="container bg-black flex justify-center mx-auto py-5">
        <nav className="flex flex-wrap xl:flex-nowrap gap-2 xl:gap-4 justify-center xl:justify-start text-sm md:text-lg">
          <NavLink className="bg-black text-white border-2 border-white text-center p-1 rounded-md" to={`${url}`} end> {/* By default, NavLink applies the active class to the link that matches the current route, and it also applies it to all parent links in the hierarchy. Therefore, if a theme is selected, the All button will also be active because the path associated with the All button (i.e. "/results") is the parent route of the theme routes. To prevent this behaviour we can add end prop to the All Navlink*/}
            All
          </NavLink>
        {priorityThemes.map((theme) => (
          <NavLink className="bg-black text-white border-2 border-white text-center p-1 rounded-md" to={`${url}/${theme.url}`} key={theme.theme_name} >
            <p>{theme.theme_name}</p>
          </NavLink>
        ))}
        </nav>
      </div>

      {match && (
      <div className="container mx-auto my-2 md:my-10 xl:my-20 flex flex-col gap-2 md:gap-10 lg:gap-14 xl:gap-16">

        <div className="flex justify-center items-center">
          <div className="text-2xl md:text-4xl font-bold">Pick Your Favourite</div>
        </div>

        {minifigureData.length === 2 && (
          <div className="h-2/3 flex gap-4 md:gap-10 justify-center items-start">

            <button className="flex-1 h-full" id="button-1" onClick={() => handleUserChoice("A")} disabled={selectedMinifigure !== null}>
              
            <div className="group w-full h-full flex flex-col gap-5 justify-center items-center">

              <div className={`relative flex justify-center items-center w-full h-full border border-red-700 border-opacity-50 group-hover:border-opacity-100 shadow-[0_0px_20px_1px_rgba(185,28,28,0.3)] transition-colors ${selectedMinifigure === "A" ? 'selected fade-out' : ''}`}>
                {selectedMinifigure === "A" && showSelectedText && (
                <span className="flex flex-col justify-center items-center absolute top-0 left-0 right-0 bottom-0 px-3 text-center bg-red-700/[0.2] z-10">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="green" viewBox="0 0 24 24" strokeWidth={10} stroke="white" className="w-12 h-12">
                    <path strokeLinecap="square" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                  </svg>
                  <span className="text-2xl md:text-4xl font-bold" style={{textShadow: '0 0.2rem 0.2rem rgba(0, 0, 0, 0.3'}}>{ minifigureData[0].win_probability }% Win Probability</span>
                </span>
                )}
                {selectedMinifigure === "A" ? (
                    <span style={{textShadow: '0px 0px 10px rgba(20, 83, 45, 1)'}} className="flex flex-col justify-center items-center absolute top-0 text-green-500 text-2xl md:text-3xl font-bold transition-transform duration-1000 transform translate-y-[-100px]">+{ratingsChangeData.ratingsChange.winner.toFixed(0)} Points</span>
                ) : selectedMinifigure === "B" ? (
                    <span style={{textShadow: '0px 0px 10px rgba(127, 29, 29, 1)'}} className="flex flex-col justify-center items-center absolute top-0 text-red-500 text-2xl md:text-3xl font-bold transition-transform duration-1000 transform translate-y-[-100px]">{ratingsChangeData.ratingsChange.loser.toFixed(0)} Points</span>
                ) : (
                    <span className="flex flex-col justify-center items-center absolute top-0"></span>
                )}
                <img className="h-60 md:h-80 object-contain" src={`${minifigureImagePath}/${minifigureData[0].minifig_id}.png`} alt={minifigureData[0].minifig_id}/>
              </div>

              <div className="w-full md:w-1/2 py-2 md:text-xl bg-red-700 bg-opacity-30 group-hover:bg-opacity-60 text-center transition-colors">
                <p>{minifigureData[0].character_name}</p>
              </div>

              

            </div>

            </button>

            <button className="flex-1 h-full" id="button-2" onClick={() => handleUserChoice("B")} disabled={selectedMinifigure !== null}>

              <div className="group w-full h-full flex flex-col gap-5 justify-center items-center">

                <div className={`relative flex justify-center items-center w-full h-full border border-blue-700 border-opacity-50 group-hover:border-opacity-100 shadow-[0_0px_20px_1px_rgba(29,78,216,0.3)] transition-colors ${selectedMinifigure === "B" ? 'selected fade-out' : ''}`}>
                  {selectedMinifigure === "B" && showSelectedText && (

                  <span className="flex flex-col justify-center items-center absolute top-0 left-0 right-0 bottom-0 px-3 text-center bg-blue-700/[0.2] z-10">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={10} stroke="white" className="w-12 h-12">
                      <path strokeLinecap="square" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                    </svg>
                    <span className="text-2xl md:text-4xl font-bold" style={{textShadow: '0 0.2rem 0.2rem rgba(0, 0, 0, 0.3'}}>{ minifigureData[1].win_probability }% Win Probability</span>
                  </span>
                  )}
                  {selectedMinifigure === "B" ? (
                    <span style={{textShadow: '0px 0px 10px rgba(20, 83, 45, 1)'}} className="flex flex-col justify-center items-center absolute top-0 text-green-500 text-2xl md:text-3xl font-bold transition-transform duration-1000 transform translate-y-[-100px]">+{ratingsChangeData.ratingsChange.winner.toFixed(0)} Points</span>
                  ) : selectedMinifigure === "A" ? (
                    <span style={{textShadow: '0px 0px 10px rgba(127, 29, 29, 1)'}} className="flex flex-col justify-center items-center absolute top-0 text-red-500 text-2xl md:text-3xl font-bold transition-transform duration-1000 transform translate-y-[-100px]">{ratingsChangeData.ratingsChange.loser.toFixed(0)} Points</span>
                  ) : (
                    <span className="flex flex-col justify-center items-center absolute top-0"></span>
                  )}
                  <img className="h-60 md:h-80 object-contain" src={`${minifigureImagePath}/${minifigureData[1].minifig_id}.png`} alt={minifigureData[1].minifig_id}/>
                </div>

                <div className="w-full md:w-1/2 py-2 md:text-xl bg-blue-700 bg-opacity-30 group-hover:bg-opacity-60 text-center transition-colors">
                  <p>{minifigureData[1].character_name}</p>
                </div>

                

              </div>

            </button>

          </div>
        )}

          <div className="flex justify-center items-center pb-5">
            <button className="bg-white text-black font-bold rounded-lg px-2 py-2" onClick={handleGenerateMatchUp}>
                  <p>Generate New Match Up</p>
            </button>
          </div>
      </div>
      )}

      <Outlet />
    </div>
  );
};

export default RankingLayout;