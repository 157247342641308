import { NavLink,  useLoaderData } from "react-router-dom";
import { useState, useEffect } from 'react';
import RatingsChartForHome from "../components/RatingsChartForHome";

const Home = () => {
    const [scrollDistance, setScrollDistance] = useState(0);
    const [selectedMinifigures, setSelectedMinifigures] = useState([]);
    const [currentMinifigureIndex, setCurrentMinifigureIndex] = useState(0);
    const [ratingsData, setRatingsData] = useState([]);

    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const topMinifigureData = useLoaderData();
    const minifigureImagePath = "/images/minifigures";

    useEffect(() => {
        const selectMinifigures = () => {
            // Select 10 random minifigures
            const selected = [];
            while (selected.length < 15) {
                const randomIndex = Math.floor(Math.random() * topMinifigureData.length);
                const selectedMinifig = topMinifigureData[randomIndex];
                if (!selected.includes(randomIndex)) {
                    selected.push({ index: randomIndex, minifig_id: selectedMinifig.minifig_id });
                }
            }
            setSelectedMinifigures(selected);
        }
        selectMinifigures();
    }, [])

    useEffect(() => {
        console.log("Selected Minifigures", selectedMinifigures);
    }, [selectedMinifigures]);

    useEffect(() => {
        // Set a timer to update the displayed minifigure every 5 seconds
        const timer = setTimeout(() => {
            setCurrentMinifigureIndex((prevIndex) => (prevIndex + 1) % selectedMinifigures.length);
        }, 5000);

        // Clear the timer on component unmount
        return () => clearTimeout(timer);
    }, [currentMinifigureIndex, selectedMinifigures]);

    const handleScroll = () => {
        const scrollTop = window.scrollY;
        setScrollDistance(scrollTop);
    };
  
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrollDistance]);

    const calculateOffset = (factor) => {
        // You can adjust the scaling factor as needed
        return factor * 0.05; // Adjust the multiplier to control the offset strength
    };

    const opacityChange = (factor) => {
        // Adjust the multiplier as needed
        return 50 + factor * 0.05; // Adjust the multiplier to control the opacity strength
    };

    const fetchRatingsData = async () => {
        try {
          // Fetch ratings for graph
          const ratingResponse = await fetch(
            `${apiUrl}/getDailyRatings.php?minifigID=${topMinifigureData[0].minifig_id}`
          );
          const ratingsData = await ratingResponse.json();
          setRatingsData(ratingsData);
          console.log(ratingsData);
        } catch (error) {
          console.error("Error fetching daily rating:", error);
        }
    }

    useEffect(() => {
       fetchRatingsData();
    }, [])

    return ( 
        <div className="flex flex-col justify-center items-center bg-black">
            <div className="w-full h-[50vh] md:h-[calc(60vh-80px)] flex justify-center">
                <div className="container h-full border-yellow-200 grid grid-cols-2">
                    <div className="flex flex-col justify-center items-start p-2 md:p-0 gap-6">
                        <h1 className="text-4xl md:text-6xl text-green-400 font-bold">Welcome to Brickelo</h1>
                        <p className="text-xl md:text-3xl font-bold">Using an ELO algorithm to find the best LEGO minifigures</p>
                        <NavLink className="bg-green-400 hover:bg-green-600 transition-colors text-lg md:text-xl rounded-lg px-2 py-1 md:py-2 md:px-3" to="/ranking">{window.innerWidth <= 640 ? "Get started" : "Compare minifigures"}</NavLink>
                    </div>
                    <div className="carousel-container">
                        {selectedMinifigures.map((minifigure, i) => (
                        <div className={`h-full w-2/3 md:w-1/2 right-[16.5%] md:right-1/4 flex justify-center items-center xl:p-10 carousel-item ${i === currentMinifigureIndex ? "visible" : ""} ${i === (currentMinifigureIndex - 1 + selectedMinifigures.length) % selectedMinifigures.length ? "previous" : ""}`} key={i}>
                            <img className="h-full object-contain" src={`${minifigureImagePath}/${minifigure.minifig_id}.png`} alt={minifigure.minifig_id}/>
                        </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="w-full h-[80vh] md:h-[calc(60vh-80px)] flex flex-col justify-center items-center">
                <h1 className="text-3xl md:text-4xl font-bold pt-5">How It Works</h1>

                <div className="container h-full grid grid-cols-1 md:grid-cols-2">
                    <div>
                        <p className="text-xl md:text-2xl text-center p-10 mb-2">Users choose their favourite between two randomly selected minifigures</p>
                        <div className="flex flex-col justify-center items-center">
                            <div className="h-32 w-80 relative">
                                <div className="svg-container svg-container1">
                                    <svg className="w-40 absolute top-0 left-0 hover:scale-110 hover:left-[-16px]" style={{ transition: "transform 0.5s, left 0.5s" }} viewBox="0 0 169 139" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M62.5002 8V19.5C62.5002 19.5 51.2126 18.9201 45.5 22.5C41.2816 25.1435 38 29 37 32C36 35 36.5 54.5 36.5 54.5V82.5C36.5 82.5 36.756 87.1403 37.5 90C38.0755 92.2119 38.5203 93.4352 39.5 95.5C40.4797 97.5648 44 99.5 47.5 101.5C51 103.5 53.5342 104.294 54 106C54.4658 107.706 54.3764 108.964 53.5 110.5C52.0728 113.001 47.573 113 46.5248 113H46.5H36.5C35.5 113 33 114.5 32 116C31 117.5 29.5 124 29.5 124H24.5C21.5 124 19.5406 123.756 16.5 124.5C12.6831 125.434 9 127.5 7.5 129C6 130.5 1 138 1 138H168C168 138 164.638 131.567 161 129C157.769 126.721 155 126 151.5 125.5C148 125 143.5 125 142 123C140.5 121 140.5 117 138 115.5C135.5 114 131.5 114 131.5 114L122 113.5C118.5 113 117.5 111 117.5 108C117.5 105 120 104 121.5 103.5C123 103 125.375 102.032 127.5 100.5C131.46 97.6461 133.667 94.5 135 90.5C136.333 86.5 136.5 68 136.5 68V55V40L135.5 33C135.5 33 135 30.5 133.5 28.5C132 26.5 128 22.5 123 21.5C118 20.5 111.5 20 111.5 20V13L111 7C110.731 4.89514 110 3 108.5 2C107 1 89.5 1 89.5 1H78C78 1 70.342 0.997225 67.5002 1.5C64.6584 2.00278 64.303 1.9683 63.5002 3C62.6974 4.03171 62.5002 8 62.5002 8Z" fill="#b91c1c" stroke="black"/>
                                    </svg>
                                </div>
                                <div className="svg-container svg-container2">
                                    <svg className="w-40 absolute top-0 left-40 hover:scale-110 hover:left-44" style={{ transition: "transform 0.5s, left 0.5s" }} viewBox="0 0 169 139" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M62.5002 8V19.5C62.5002 19.5 51.2126 18.9201 45.5 22.5C41.2816 25.1435 38 29 37 32C36 35 36.5 54.5 36.5 54.5V82.5C36.5 82.5 36.756 87.1403 37.5 90C38.0755 92.2119 38.5203 93.4352 39.5 95.5C40.4797 97.5648 44 99.5 47.5 101.5C51 103.5 53.5342 104.294 54 106C54.4658 107.706 54.3764 108.964 53.5 110.5C52.0728 113.001 47.573 113 46.5248 113H46.5H36.5C35.5 113 33 114.5 32 116C31 117.5 29.5 124 29.5 124H24.5C21.5 124 19.5406 123.756 16.5 124.5C12.6831 125.434 9 127.5 7.5 129C6 130.5 1 138 1 138H168C168 138 164.638 131.567 161 129C157.769 126.721 155 126 151.5 125.5C148 125 143.5 125 142 123C140.5 121 140.5 117 138 115.5C135.5 114 131.5 114 131.5 114L122 113.5C118.5 113 117.5 111 117.5 108C117.5 105 120 104 121.5 103.5C123 103 125.375 102.032 127.5 100.5C131.46 97.6461 133.667 94.5 135 90.5C136.333 86.5 136.5 68 136.5 68V55V40L135.5 33C135.5 33 135 30.5 133.5 28.5C132 26.5 128 22.5 123 21.5C118 20.5 111.5 20 111.5 20V13L111 7C110.731 4.89514 110 3 108.5 2C107 1 89.5 1 89.5 1H78C78 1 70.342 0.997225 67.5002 1.5C64.6584 2.00278 64.303 1.9683 63.5002 3C62.6974 4.03171 62.5002 8 62.5002 8Z" fill="#1d4ed8" stroke="black"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="mb-44 md:mb-0">
                        <p className="text-xl md:text-2xl text-center p-10 mb-2">Their ratings are calculated based on which minifigure is chosen and their ratings before the match up</p>
                        <div className="flex flex-col justify-center items-center">
                            <div className="flex flex-row justify-center items-center">
                                <div className="relative transition-transform duration-75 transform" style={{transform: `translateY(${calculateOffset(scrollDistance)}px)`}}>
                                    <p className="absolute top-[-30px] left-10 md:top-10 md:left-[-50px] text-red-400 font-bold" style={{ opacity: `${opacityChange(scrollDistance)}%` }}>-8 Points</p>
                                    <svg className="w-40" 
                                        viewBox="0 0 169 139" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M62.5002 8V19.5C62.5002 19.5 51.2126 18.9201 45.5 22.5C41.2816 25.1435 38 29 37 32C36 35 36.5 54.5 36.5 54.5V82.5C36.5 82.5 36.756 87.1403 37.5 90C38.0755 92.2119 38.5203 93.4352 39.5 95.5C40.4797 97.5648 44 99.5 47.5 101.5C51 103.5 53.5342 104.294 54 106C54.4658 107.706 54.3764 108.964 53.5 110.5C52.0728 113.001 47.573 113 46.5248 113H46.5H36.5C35.5 113 33 114.5 32 116C31 117.5 29.5 124 29.5 124H24.5C21.5 124 19.5406 123.756 16.5 124.5C12.6831 125.434 9 127.5 7.5 129C6 130.5 1 138 1 138H168C168 138 164.638 131.567 161 129C157.769 126.721 155 126 151.5 125.5C148 125 143.5 125 142 123C140.5 121 140.5 117 138 115.5C135.5 114 131.5 114 131.5 114L122 113.5C118.5 113 117.5 111 117.5 108C117.5 105 120 104 121.5 103.5C123 103 125.375 102.032 127.5 100.5C131.46 97.6461 133.667 94.5 135 90.5C136.333 86.5 136.5 68 136.5 68V55V40L135.5 33C135.5 33 135 30.5 133.5 28.5C132 26.5 128 22.5 123 21.5C118 20.5 111.5 20 111.5 20V13L111 7C110.731 4.89514 110 3 108.5 2C107 1 89.5 1 89.5 1H78C78 1 70.342 0.997225 67.5002 1.5C64.6584 2.00278 64.303 1.9683 63.5002 3C62.6974 4.03171 62.5002 8 62.5002 8Z" fill="#b91c1c" stroke="black"/>
                                    </svg>
                                </div>
                                <div className="relative transition-transform duration-75 transform" style={{transform: `translateY(-${calculateOffset(scrollDistance)}px)`}}>
                                    <p className="absolute bottom-[-30px] right-10 md:top-10 md:right-[-50px] text-green-400 font-bold" style={{ opacity: `${opacityChange(scrollDistance)}%` }}>+8 Points</p>
                                    <svg className="w-40"
                                        viewBox="0 0 169 139" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M62.5002 8V19.5C62.5002 19.5 51.2126 18.9201 45.5 22.5C41.2816 25.1435 38 29 37 32C36 35 36.5 54.5 36.5 54.5V82.5C36.5 82.5 36.756 87.1403 37.5 90C38.0755 92.2119 38.5203 93.4352 39.5 95.5C40.4797 97.5648 44 99.5 47.5 101.5C51 103.5 53.5342 104.294 54 106C54.4658 107.706 54.3764 108.964 53.5 110.5C52.0728 113.001 47.573 113 46.5248 113H46.5H36.5C35.5 113 33 114.5 32 116C31 117.5 29.5 124 29.5 124H24.5C21.5 124 19.5406 123.756 16.5 124.5C12.6831 125.434 9 127.5 7.5 129C6 130.5 1 138 1 138H168C168 138 164.638 131.567 161 129C157.769 126.721 155 126 151.5 125.5C148 125 143.5 125 142 123C140.5 121 140.5 117 138 115.5C135.5 114 131.5 114 131.5 114L122 113.5C118.5 113 117.5 111 117.5 108C117.5 105 120 104 121.5 103.5C123 103 125.375 102.032 127.5 100.5C131.46 97.6461 133.667 94.5 135 90.5C136.333 86.5 136.5 68 136.5 68V55V40L135.5 33C135.5 33 135 30.5 133.5 28.5C132 26.5 128 22.5 123 21.5C118 20.5 111.5 20 111.5 20V13L111 7C110.731 4.89514 110 3 108.5 2C107 1 89.5 1 89.5 1H78C78 1 70.342 0.997225 67.5002 1.5C64.6584 2.00278 64.303 1.9683 63.5002 3C62.6974 4.03171 62.5002 8 62.5002 8Z" fill="#1d4ed8" stroke="black"/>
                                    </svg>   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full h-[100vh] md:h-[calc(60vh-80px)] flex flex-col justify-center items-center">
                <div className="container h-full border-yellow-200 grid grid-cols-1 md:grid-cols-2">
                    <div className="flex flex-col justify-center items-center md:items-start gap-6">
                        <h1 className="text-3xl md:text-5xl text-center mt-12 md:mt-0 md:text-left font-bold">View the highest rated LEGO minifigures</h1>
                        <NavLink className="bg-green-400 hover:bg-green-600 transition-colors text-lg md:text-xl rounded-lg py-2 px-3" to="/results">Explore the top 100</NavLink>
                    </div>
                    <div className="flex flex-col justify-center items-start gap-4">
                        {topMinifigureData.map((minifigure, i) => (
                            i < 3 ? <div key={i} className="container grid grid-cols-4 md:grid-cols-5 text-xl">
                                        <div className="grow basis-0 flex justify-center items-center">
                                            <p className="text-green-400 font-bold">{i + 1}</p>
                                        </div>
                                        <div className="grow-[2] basis-0 flex justify-center items-center">
                                            <img className="h-32 object-contain" src={`${minifigureImagePath}/${minifigure.minifig_id}.png`}/>
                                        </div>
                                        <div className="grow-[4] basis-0 flex items-center">
                                            <p className="">{minifigure.character_name}</p>  
                                        </div>
                                        <div className="grow basis-0 flex justify-center items-center">
                                            <p className="text-green-400 font-bold">{Math.round(minifigure.rating)}</p>
                                        </div>
                                        <div>

                                        </div>
                                    </div>
                            : null
                        ))}
                    </div>
                </div>
            </div>

            <div className="w-full h-[calc(60vh-80px)] flex flex-col justify-center items-center">
                <div className="container h-full border-yellow-200 grid grid-cols-1 md:grid-cols-2">
                    <div className="relative order-2 md:order-1">
                        <div className="absolute inset-0 flex justify-center items-center opacity-50 md:opacity-0 md:hover:opacity-50 md:transition-opacity md:duration-300">
                        <img className="h-40 md:h-72" src={`${minifigureImagePath}/${topMinifigureData[0].minifig_id}.png`}/>
                        </div>
                        <RatingsChartForHome chartData={ratingsData}/>
                    </div>
                    <div className="flex flex-col justify-center items-start order-1 md:order-2">
                        <h1 className="text-3xl md:text-5xl text-center md:text-left font-bold px-2 md:px-14">See how each minifigure's rating has changed over time</h1>
                    </div>
                </div>
            </div>
                
            
        </div>
     );
}
 
export default Home;

//loader function
export const randomTopMinifiguresLoader = async () => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const response = await fetch(`${apiUrl}/getRandomTopMinifigures.php`)
    return response.json()
}