import { Link } from "react-router-dom";

const NotFound = () => {
    return ( 
        <div className="flex justify-center items-center">
            <div className="container m-40 flex flex-col gap-20 justify-center items-center text-center">
                <h1 className="text-4xl font-bold">Page not found!</h1>

                <Link className="bg-white text-black p-2 rounded-md text-xl font-bold" to="/">Back to Homepage</Link>
            </div>
        </div>
     );
}
 
export default NotFound;