import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

const CharacterPage = () => {
    const {searchValue} = useParams(); // get the search value (i.e. character name) from the url. The url is specified in the SearchBar component
    const [charactersData, setCharactersData] = useState([]);
    const navigate = useNavigate();

    const minifigureImagePath = "/images/minifigures";
    // Determine the API base URL based on the environment
    const apiUrl = process.env.REACT_APP_API_BASE_URL;

    const fetchCharacterData = async () => {
        try {
          // Fetch minifigures matching the entered character name
          const charactersResponse = await fetch(
            `${apiUrl}/getMinifiguresByCharacter.php?characterName=${searchValue}`
          );
          const charactersData = await charactersResponse.json();
          setCharactersData(charactersData);
          console.log(charactersData);
        } catch (error) {
          console.error("Error fetching minifigures by character:", error);
        }
    }

    useEffect(() => {
        fetchCharacterData();
    }, [searchValue]); // Call the effect whenever the character name changes

    const handleButtonClick = (themeUrl, minifigId) => {
        // Navigate to the specified path when the button is clicked
        navigate(`/results/${themeUrl}/minifigures/${minifigId}`);
    };

    return (
        <div className="flex flex-col gap-10 justify-center items-center p-2 md:p-10">
      
            <div className="container grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">

                {charactersData.map((minifigure) => (
                    <div key={minifigure.id} className="flex flex-col gap-2 md:gap-10 border-b border-white hover:border-green-500 transition-all duration-300 border-opacity-20 p-2">
                        <button onClick={() =>
                                    handleButtonClick(
                                        minifigure.theme_url,
                                        minifigure.minifig_id
                                    )
                                } className="flex flex-col justify-center items-center p-2">
                            <img className="h-40 m-h-[40] object-contain" src={`${minifigureImagePath}/${minifigure.minifig_id}.png`} alt={minifigure.minifig_id}/>
                            <div className="w-full flex justify-center items-start overflow-hidden">
                                <p className="text-center truncate">{minifigure.minifig_name}</p>
                            </div>
                            <p className="mt-1 text-center"><span className="font-bold text-green-500">Number:</span> {minifigure.minifig_id}</p>
                            <p><span className="font-bold text-green-500">Released:</span> {minifigure.year}</p>
                            <p><span className="font-bold text-green-500">Rating:</span>{Math.round(minifigure.rating)}</p>
                            <button className="bg-green-500 mt-2 py-1 px-2 rounded-md hover:bg-green-600"
                                onClick={() =>
                                    handleButtonClick(
                                        minifigure.theme_url,
                                        minifigure.minifig_id
                                    )
                                }
                            >
                                View Stats
                            </button>
                        </button>
                    </div>
                ))}
            
            </div>
        </div>
     );
}
 
export default CharacterPage;