import { useRouteError } from "react-router-dom";

const MinifigureError = () => {
    const error = useRouteError();
    return (
      <div>
        <p>Failed to fetch minifigures.</p>
        <p>Error message: {error.message}</p>
      </div>
    );
  };
 
export default MinifigureError;