import { useEffect, useState } from 'react';
import { Outlet, Link, useMatch } from 'react-router-dom';
import BlogData from '../data/BlogData';

const BlogLayout = () => {
    const imagePath = "/images/minifigures/blogs";

    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        setBlogs(BlogData);
    }, []);

    const match = useMatch('/blog');

    return (
        <div className='flex justify-center items-center'>
            {match && (
                <div className='container p-2 sm:p-10'>
                <h1 className='text-4xl font-bold text-green-400 text-center'>Latest Blogs</h1>
                {blogs.map((entry) => (
                    <Link to={`/blog/${entry.slug}`} key={entry.id}>
                        <div className='bg-[#111111] my-10 lg:mx-20 xl:mx-60 p-2 rounded-2xl flex flex-col justify-center items-center gap-4'>
                            
                            <div className='sm:w-1/2 flex justify-center items-center'>
                                <div className='flex flex-col gap-2'>
                                    <div className='text-2xl font-bold text-center'>{entry.title}</div>
                                    <div className='text-center text-green-400'>{entry.date}</div>  
                                </div>
                            </div>

                            <div className='flex flex-col md:flex-row gap-4 sm:gap-10 px-4 sm:px-10'>
                                <div className='w-full md:w-1/2'>
                                    <img src={`${imagePath}/${entry.previewImg}`} className="w-full h-auto rounded-xl"/>
                                </div>
                                <div className='w-full md:w-1/2 text-lg flex justify-center items-top'>{entry.summary}</div>
                            </div>

                            <Link to={`/blog/${entry.slug}`} className='bg-green-500 hover:bg-green-600 transition-colors p-2 rounded-lg'>Read More</Link>

                        </div>
                    </Link>
                ))}
                </div>
            )}
          
          <Outlet context={[blogs, setBlogs]}/>
        </div>
      );
}
 
export default BlogLayout;