import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { useState, useEffect } from "react";

const ChartForBlog5 = () => {
    const labels = [
        "Deutscher Fussball-Bund",
        "Team GB",
        "Harry Potter Series 1",
        "The Simpsons Series 2",
        "The Simpsons Series 1",
        "Harry Potter Series 2",
        "Looney Tunes",
        "The Muppets",
        "Series 2",
        "Series 3",
        "Disney Series 2",
        "Series 20",
        "The LEGO NINJAGO Movie",
        "Series 1",
        "Disney Series 1",
        "Series 19",
        "Series 18",
        "Series 4",
        "Series 22",
        "Series 16",
        "Series 5",
        "Series 10",
        "Series 7",
        "Dungeons & Dragons",
        "The LEGO Batman Movie Series 2",
        "Series 17",
        "Series 21",
        "Disney 100",
        "The LEGO Movie",
        "Series 24",
        "Series 8",
        "Series 23",
        "Series 6",
        "Series 11",
        "Series 25",
        "The LEGO Movie 2",
        "Series 12",
        "DC Super Heroes",
        "Series 9",
        "Series 15",
        "The LEGO Batman Movie Series 1",
        "Series 13",
        "Marvel Studios Series 1",
        "Marvel Studios Series 2",
        "Series 26",
        "Series 14"
    ];

    const data = [
        751.44,
        871.52,
        926.24,
        933.55,
        935.15,
        965.10,
        966.45,
        975.61,
        989.74,
        997.92,
        1005.06,
        1007.87,
        1021.84,
        1027.14,
        1027.94,
        1030.93,
        1032.12,
        1034.69,
        1035.75,
        1038.42,
        1044.76,
        1045.18,
        1045.39,
        1050.64,
        1051.68,
        1053.91,
        1054.82,
        1057.63,
        1066.34,
        1066.48,
        1067.33,
        1067.48,
        1067.63,
        1070.17,
        1070.40,
        1071.99,
        1073.70,
        1075.46,
        1083.01,
        1090.64,
        1094.72,
        1103.25,
        1113.48,
        1135.40,
        1150.40,
        1171.15
    ];

    const [indexAxis, setIndexAxis] = useState('x'); // Default to 'x' for larger screens

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 640) { // 640px is Tailwind's 'sm' breakpoint
                setIndexAxis('y');
            } else {
                setIndexAxis('x');
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const average = data.reduce((sum, value) => sum + value, 0) / data.length;
    const minValue = Math.min(...data);
    const maxValue = Math.max(...data);

    const getColorForValue = (value) => {
        const normalizedValue = (value - minValue) / (maxValue - minValue);
        let red, green;

        if (normalizedValue <= 0.5) {
            red = 255;
            green = Math.floor(2 * normalizedValue * 255);
        } else {
            red = Math.floor((1 - 2 * (normalizedValue - 0.5)) * 255);
            green = 255;
        }

        return `rgba(${red}, ${green}, 0, 0.5)`;
    };

    const backgroundColors = data.map(getColorForValue);

    const BarChart = () => {
        return {
            labels: labels,
            datasets: [
                {
                    data: data,
                    label: "",
                    backgroundColor: backgroundColors,
                    borderWidth: 1,
                    hoverBackgroundColor: backgroundColors,
                    hoverBorderColor: backgroundColors,
                    //barThickness: 10,
                    //maxBarThickness: 20,
                },
                {
                    label: "Average",
                    data: Array(labels.length).fill(average),
                    type: "line",
                    borderColor: "rgba(255, 255, 255, 1)",
                    borderWidth: 2,
                    borderDash: [8, 5],
                    pointRadius: 0,
                }
            ]
        };
    };

    const options = {
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            [indexAxis === 'x' ? 'y' : 'x']: {
                title: {
                    display: true,
                    text: "Rating",
                    color: 'white',
                },
                ticks: {
                    color: 'white',
                },
                min: indexAxis === 'y' ? undefined : 700, // Set min to 700 only on x-axis for larger screens
            },
            [indexAxis]: {
                ticks: {
                    color: 'white',
                    autoSkip: indexAxis === 'y' ? true: false,
                },
            }
        },
        indexAxis: indexAxis,
    };

    return (
        <div className='w-full flex flex-col justify-center items-center mb-3'>
            <h2 className="p-2 font-bold text-lg">Average CMF Series Rating</h2>
            <div className='relative w-full'>
                <Bar data={BarChart()} options={{...options, responsive: true}}/>
            </div>
        </div>
    );
};

export default ChartForBlog5;
