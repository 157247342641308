import { useParams, Outlet, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { Tooltip } from 'react-tooltip'
import ThemeMinifigurePlaceholder from "../components/ThemeMinifigurePlaceholder";

const ThemePage = ({themesData}) => {
    const {themeUrl} = useParams()
    const navigate = useNavigate();

    const minifigureImagePath = "/images/minifigures/small/";
    const apiUrl = process.env.REACT_APP_API_BASE_URL;

    const theme = themesData.find((themeData) => themeData.url === themeUrl);
    const themeName = theme ? theme.theme_name : "";

    const [themeMinifigures, setThemeMinifigures] = useState([]);
    const [loadedImages, setLoadedImages] = useState([]);
    const [loading, setLoading] = useState(true);

    // Should I change these values to be the current refs by default?
    const [sortBy, setSortBy] = useState("minifig_id"); // Default sorting by rating
    const [sortOrder, setSortOrder] = useState("asc"); // Default ascending order
    const [lastClicked, setLastClicked] = useState("minifig_id"); // Defaut last clicked. This determines where the svg arrow is placed on component mount

    // useRef variables
    const sortByRef = useRef("minifig_id");
    const sortOrderRef = useRef("asc");
    const lastClickedRef = useRef("minifig_id");

    // Load sorting preferences from localStorage on component mount
    useEffect(() => {
        const savedSortBy = localStorage.getItem("sort_by");
        const savedSortOrder = localStorage.getItem("sort_order");
        const savedLastClicked = localStorage.getItem("last_clicked");
    
        if (savedSortBy) {
            setSortBy(savedSortBy);
            sortByRef.current = savedSortBy;
        }
        if (savedSortOrder) {
            setSortOrder(savedSortOrder);
            sortOrderRef.current = savedSortOrder;
        }
        if (savedLastClicked) {
            setLastClicked(savedLastClicked);
            lastClickedRef.current = savedLastClicked;
        }
    
        console.log("Saved Sort By", savedSortBy);
        console.log("Saved Sort Order", savedSortOrder);
        console.log("Saved Last Clicked", savedLastClicked);
    }, []);

    // I either need to stop the fetching of minifigures between re-renders or sort them by the current refs
    const fetchThemeMinifigures = async () => {
        try {
          const response = await fetch(`${apiUrl}/getAllThemeMinifigures.php?themeUrl=${themeUrl}`);
          if (!response.ok) {
            throw Error("Error fetching minifigures");
          }
          const data = await response.json();
          setThemeMinifigures(data);

          // Iterate through each minifigure and load its image
          data.forEach(minifigure => {
            const image = new Image();
            image.src = `${minifigureImagePath}/${minifigure.minifig_id}.png`;
            image.onload = () => {
                // When the image for each minifigure loads, update loadedImages array by adding the loaded minifigure's ID
                setLoadedImages(prevLoaded => [...prevLoaded, minifigure.minifig_id]);
            };
          });
        } catch (error) {
          console.error("Error fetching minifigures:", error);
        } finally {
          setLoading(false);
        }
      };
    
    useEffect(() => {
        // Fetch minifigures when the component mounts
        fetchThemeMinifigures();
    
        // Cleanup function to cancel ongoing fetch when the component is unmounted
        return () => {
          // You can cancel ongoing requests here if necessary
        };
    }, [themeUrl]); // Run the useEffect hook when the themeUrl parameter changes in the path (i.e. when a user has clicked on a different theme)

    // function to sort minifigures
    const sortMinifigures = () => {
        // Sorting logic based on the selected criteria and order
        let sortedMinifigures = [...themeMinifigures];
        switch (sortBy) {
            case "rating":
                sortedMinifigures.sort((a, b) => {
                const order = sortOrder === "asc" ? 1 : -1;
                return order * (a.rating - b.rating);
                });
                break;
            case "character_name":
                sortedMinifigures.sort((a, b) => {
                const order = sortOrder === "asc" ? 1 : -1;
                return order * a.character_name.localeCompare(b.character_name);
                });
                break;
            case "minifig_id":
                sortedMinifigures.sort((a, b) => {
                const order = sortOrder === "asc" ? 1 : -1;
                return order * a.minifig_id.localeCompare(b.minifig_id);
                });
            break;
            case "year":
                sortedMinifigures.sort((a, b) => {
                const order = sortOrder === "asc" ? 1 : -1;
                return order * (a.year - b.year);
                });
            break;
                default:
            break;
        }
        setThemeMinifigures(sortedMinifigures);
      };

    useEffect(() => {
        // Trigger minifigure sorting when sortBy or sortOrder changes
        sortMinifigures();
    }, [sortBy, sortOrder]);

    const toggleSortOrder = (clickedSortBy) => {
        // Use state variables temporarily for triggering re-render
        setSortBy(clickedSortBy);
        setSortOrder((prevSortOrder) => (lastClicked === clickedSortBy ? (prevSortOrder === "asc" ? "desc" : "asc") : prevSortOrder));
        setLastClicked(clickedSortBy);
    
        
    };

    useEffect(() => {
        // Update useRef values without causing re-render
        sortOrderRef.current = sortOrder;
        sortByRef.current = sortBy;
        lastClickedRef.current = lastClicked;

        // Save sorting preferences to localStorage
        localStorage.setItem("sort_by", sortBy);
        localStorage.setItem("sort_order", sortOrder);
        localStorage.setItem("last_clicked", lastClicked);
      }, [sortBy, sortOrder, lastClicked]);
    

    // Function to calculate background color based on rating
    const calculateBackgroundColor = (rating) => {
        const minRating = 920;
        const maxRating = 1100;

        // Map the rating to a value between 0 and 1
        const normalizedRating = (rating - minRating) / (maxRating - minRating);

        // Interpolate between red and green based on the normalized rating
        //const red = Math.round(255 * (1 - normalizedRating));
        //const green = Math.round(255 * normalizedRating);
        const green = Math.round(70 * normalizedRating);

        // Return the RGB color
        //return `rgba(${red}, ${green}, 0, 0.5)`;
        return `hsla(${green}, 100%, 50%, 0.8)`;
    };

    const handleClick = (minifigure) => {
        navigate(`/results/${themeUrl}/minifigures/${minifigure.minifig_id}`);
    };

    return (
        <>
        <div className="text-center text-4xl font-bold p-10">{themeName}</div>
        <div className="flex flex-col gap-2 justify-center items-center">
            
            <div className="flex gap-10 justify-center items-center pb-5">
                <button onClick={() => toggleSortOrder("rating")} className="h-10 w-32 rounded-md text-md px-2 py-1 flex justify-between items-center">
                    <p className="text-lg h-full">Rating</p>
                    {sortOrder === 'asc' && lastClicked === 'rating' ? 
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="green" className="w-6 h-full">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" />
                    </svg>
                    : sortOrder === 'desc' && lastClicked === 'rating' ?
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="red" className="w-6 h-full">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18" />
                    </svg>
                    : <div className="w-6 h-full">

                    </div>
                    }
                </button>
                <button onClick={() => toggleSortOrder("character_name")} className="h-10 w-32 rounded-md text-md px-2 py-1 flex justify-between items-center">
                    <p className="text-lg h-full">Name</p>
                    {sortOrder=== 'asc' && lastClicked === 'character_name' ? 
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="green" className="w-6 h-full">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" />
                    </svg>
                    : sortOrder === 'desc' && lastClicked === 'character_name' ? 
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="red" className="w-6 h-full">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18" />
                    </svg>
                    : <div className="w-6 h-full">

                    </div>
                    }
                </button>
                <button onClick={() => toggleSortOrder("minifig_id")} className="h-10 w-32 rounded-md text-md px-2 py-1 flex justify-between items-center">
                    <p className="text-lg h-full">Number</p>
                    {sortOrder === 'asc' && lastClicked === 'minifig_id' ? 
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="green" className="w-6 h-full">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" />
                    </svg>
                    : sortOrder === 'desc' && lastClicked === 'minifig_id' ?
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="red" className="w-6 h-full">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18" />
                    </svg>
                    : <div className="w-6 h-full">

                    </div>
                    }
                </button>
                <button onClick={() => toggleSortOrder("year")} className="h-10 w-32 rounded-md text-md px-2 py-1 flex justify-between items-center">
                    <p className="text-lg h-full">Year</p>
                    {sortOrder === 'asc' && lastClicked === 'year' ? 
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="green" className="w-6 h-full">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" />
                    </svg>
                    : sortOrder === 'desc' && lastClicked === 'year' ? 
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="red" className="w-6 h-full">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18" />
                    </svg>
                    : <div className="w-6 h-full">

                    </div>
                    }
                </button>
            </div>
        </div>
        
        <div className="grid grid-cols-22 gap-y-2 place-items-center pb-4">
        {themeMinifigures.map((minifigure, i) => (
            <div
                key={i}
                onClick={() => handleClick(minifigure)}
                to={`results/${minifigure.theme_url}/minifigures/${minifigure.minifig_id}`}
                className="h-20 w-20 border rounded-lg relative group cursor-pointer"
                style={{ backgroundColor: calculateBackgroundColor(minifigure.rating) }}
                data-tooltip-id="minifig-tooltip"
                data-tooltip-content={`${minifigure.character_name}`}
            >
                {loadedImages.includes(minifigure.minifig_id) ? (
                    <>
                        <img
                            className="h-full w-full object-contain transition-opacity group-hover:opacity-40"
                            src={`${minifigureImagePath}/${minifigure.minifig_id}.png`}
                            alt={minifigure.minifig_id}
                        />
                        <div className="absolute inset-0 border opacity-0 flex justify-center items-center transition-opacity group-hover:opacity-100"><p className="text-center">Rating: <span className="font-bold">{Math.round(minifigure.rating)}</span></p></div>
                    </>
                    ) : (
                    <div className="flex justify-center items-center p-2">
                        <ThemeMinifigurePlaceholder/>
                    </div>
                    )}
                    <Tooltip id="minifig-tooltip" place={'top'} style={{ backgroundColor: "rgb(84, 84, 84)", color: "#fffff" }}/>
            </div>
        ))}
        </div>
        </> 
     );
}
 
export default ThemePage;