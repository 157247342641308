import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import trendlineLinear from 'chartjs-plugin-trendline';


const ChartForBlog5 = () => {
    const labels = [
        2010, 2010, 2011, 2011, 2011,
        2012, 2012, 2012, 2012, 2013,
        2013, 2013, 2014, 2014, 2014,
        2015, 2015, 2015, 2016, 2016,
        2016, 2016, 2017, 2017, 2017,
        2018, 2018, 2018, 2019, 2019,
        2019, 2020, 2020, 2020, 2021,
        2021, 2021, 2022, 2022, 2022,
        2023, 2023, 2023, 2024, 2024,
        2024
    ];
    
    
    const data = [
        1027.14, 989.74, 997.92, 1034.69, 1044.76,
        1067.63, 1045.39, 871.52, 1067.33, 1083.01,
        1045.18, 1070.17, 1066.34, 935.15, 1073.70,
        1103.25, 933.55, 1171.15, 1090.64, 1027.94,
        751.44, 1038.42, 1094.72, 1053.91, 1021.84,
        1051.68, 1032.12, 926.24, 1071.99, 1005.06,
        1030.93, 1075.46, 1007.87, 965.10, 1054.82,
        966.45, 1113.48, 1035.75, 975.61, 1067.48,
        1066.48, 1057.63, 1135.40, 1070.40, 1150.40,
        1050.64
    ];

    // Min and max values for normalization
    const minValue = Math.min(...data);
    const maxValue = Math.max(...data);

    // Function to calculate color on a red-yellow-green gradient
    const getColorForValue = (value) => {
        const normalizedValue = (value - minValue) / (maxValue - minValue);
        
        let red, green;

        if (normalizedValue <= 0.5) {
            // Red to yellow gradient
            red = 255;
            green = Math.floor(2 * normalizedValue * 255);
        } else {
            // Yellow to green gradient
            red = Math.floor((1 - 2 * (normalizedValue - 0.5)) * 255);
            green = 255;
        }
        
        return `rgba(${red}, ${green}, 0, 0.5)`;
    };

    // Generate background colors based on data values
    const backgroundColors = data.map(getColorForValue);

    const BarChart = () => {
        return {
            labels: labels,
            datasets: [
                {
                    data: data,
                    label: "",
                    backgroundColor: backgroundColors,
                    borderWidth: 1,
                    hoverBackgroundColor: backgroundColors, // Matches hover color to main color
                    hoverBorderColor: backgroundColors, // Matches border color to main color
                    //barThickness: 10,
                    //maxBarThickness: 20,
                },
                {
                    label: "Line of Best Fit",
                    data: data,
                    type: "line",
                    trendlineLinear: {
                        colorMin: 'white',
                        colorMax: 'white',
                        lineStyle: "dashed",
                        width: 2,
                        label: {
                            color: 'rgba(255, 255, 255, 0.8)',
                            text: 'Line of best fit',
                            display: true,
                            displayValue: false,
                        },
                    }
                }
            ]
        };
    };

    const options = {
        plugins: {
            legend: {
                display: false, // Hides the legend at the top of the chart
            },
        },
        scales: {
            y: {
                title: {
                    display: true, // Set display to true to show the x-axis label
                    text: "Rating", // The label for the x-axis
                    color: 'white',
                },
                ticks: {
                    color: 'white', // Set the text color of y-axis ticks
                },
                min: 700,
            },
            x: {
                ticks: {
                    color: 'white', // Set the text color of x-axis ticks
                    autoSkip: true,
                },
                
            }
        },
        indexAxis: 'x',
    };

    return ( 
        <div className='w-full flex flex-col justify-center items-center mb-3'>
            <h2 className="p-2 font-bold text-lg">Average CMF Series Rating Over Time</h2>
            <div className='relative w-full p-0'>
                <Bar data={BarChart()} options={{...options, responsive: true}}  plugins={[trendlineLinear]}/>
            </div>
        </div>
    );
}

export default ChartForBlog5;
