import { useState, useEffect } from "react";
import CompareMinifigurePlaceholder from "../components/CompareMinifigurePlaceholder";
import ThemeMinifigurePlaceholder from "../components/ThemeMinifigurePlaceholder";

const CompareLayout = () => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const minifigureImagePath = "/images/minifigures";

    const [searchValue1, setSearchValue1] = useState("");
    const [searchValue2, setSearchValue2] = useState("");
    const [minifigure1, setMinifigure1] = useState([]);
    const [minifigure2, setMinifigure2] = useState([]);
    const [minifigure1NotFound, setMinifigure1NotFound] = useState(false)
    const [minifigure2NotFound, setMinifigure2NotFound] = useState(false);
    const [headToHeadData, setHeadToHeadData] = useState("");
    const [comparisonRun, setComparisonRun] = useState(false);
    const [loadedImages1, setLoadedImages1] = useState([]);
    const [loadedImages2, setLoadedImages2] = useState([]);
    const [loading, setLoading] = useState(false);
    const [themeMinifigures1, setThemeMinifigures1] = useState([]);
    const [themeMinifigures2, setThemeMinifigures2] = useState([]);

    const handleSelection1 = async () => {
        let dataFetchedSuccessfully = false;
        // Only try and fetch data if at least 5 characters have been entered into the search field
        if (searchValue1.length >= 5) {
            try {
                    const response = await fetch(`${apiUrl}/getSelection1.php?minifigID=${searchValue1}`);
                    if (!response.ok) {
                    throw Error("Error fetching minifigures");
                    }
                    const data = await response.json();
                    dataFetchedSuccessfully = true;
                    setMinifigure1(data);
                    setHeadToHeadData("");

                    const minifigureTheme1 = data[0].theme;
                    if (minifigureTheme1) {
                        const themeResponse = await fetch(`${apiUrl}/getThemeMinifigures.php?theme=${minifigureTheme1}`);
                        if (!themeResponse.ok) {
                            throw Error("Error fetching theme minifigures");
                        }
                        const themeData = await themeResponse.json();
                        setThemeMinifigures1(themeData);

                        themeData.forEach(minifigure => {
                            const image = new Image();
                            image.src = `${minifigureImagePath}/${minifigure.minifig_id}.png`;
                            image.onload = () => {
                                // Update loadedImages with the newly loaded minifigure's ID
                                setLoadedImages1(prevLoaded => [...prevLoaded, minifigure.minifig_id]);
                            };
                        });
                    }
            } catch (error) {
                //console.error("Error fetching minifigures:", error);
                setMinifigure1NotFound(true);
            } finally {
                setTimeout(() => {
                    if (dataFetchedSuccessfully) {
                        setMinifigure1NotFound(false);
                    } else {
                        setMinifigure1NotFound(true);
                    }
                }, 0)
            }
        }
      };

    const handleSelection2 = async () => {
        let dataFetchedSuccessfully = false;
        // Only try and fetch data if at least 5 characters have been entered into the search field
        if (searchValue2.length >= 5) {
            try {
                    const response = await fetch(`${apiUrl}/getSelection2.php?minifigID=${searchValue2}`);
                    if (!response.ok) {
                    throw Error("Error fetching minifigures");
                    }
                    const data = await response.json();
                    dataFetchedSuccessfully = true;
                    setMinifigure2(data);
                    setHeadToHeadData("");

                    const minifigureTheme2 = data[0].theme;
                    if (minifigureTheme2) {
                        const themeResponse = await fetch(`${apiUrl}/getThemeMinifigures.php?theme=${minifigureTheme2}`);
                        if (!themeResponse.ok) {
                            throw Error("Error fetching theme minifigures");
                        }
                        const themeData = await themeResponse.json();
                        setThemeMinifigures2(themeData);

                        themeData.forEach(minifigure => {
                            const image = new Image();
                            image.src = `${minifigureImagePath}/${minifigure.minifig_id}.png`;
                            image.onload = () => {
                                // Update loadedImages with the newly loaded minifigure's ID
                                setLoadedImages2(prevLoaded => [...prevLoaded, minifigure.minifig_id]);
                            };
                        });
                    }
            } catch (error) {
                //console.error("Error fetching minifigures:", error);
                setMinifigure2NotFound(true);
            } finally {
                setTimeout(() => {
                    if (dataFetchedSuccessfully) {
                        setMinifigure2NotFound(false);
                    } else {
                        setMinifigure2NotFound(true);
                    }
                }, 0)
            }
        }
      };

    const compareSelectedMinifigures = async () => {
        setLoading(true);
        if (searchValue1 !== '' && searchValue2 !== '') { //Check if the search values are not empty strings before running the comparison
                setComparisonRun(true);
                try {
                    const response = await fetch(`${apiUrl}/getHeadToHead.php?minifigID1=${searchValue1}&minifigID2=${searchValue2}`);
                    if (!response.ok) {
                    throw Error("Error fetching minifigures");
                    }
                    const data = await response.json();
                    setHeadToHeadData(data);
                    console.log("Head to head data", headToHeadData);
                    console.log("Loading state", loading);
                    
            } catch (error) {
                //console.error("Error fetching minifigures:", error);
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            } finally {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            }
        }
    }

    useEffect(() => {
        if (minifigure1 && minifigure1.length > 0 && minifigure2 && minifigure2.length > 0) {
            compareSelectedMinifigures();
            //console.log("Comparison run");
        }
        // Cleanup function to cancel ongoing fetch when the component is unmounted
        return () => {
          // You can cancel ongoing requests here if necessary
        };
    }, [minifigure1, minifigure2]);

    useEffect(() => {
        // make the "No minifigure found with that id" message disappear at first if the user tries searching for a new id
        setMinifigure1NotFound(false);
        handleSelection1();
    }, [searchValue1]);

    useEffect(() => {
        // make the "No minifigure found with that id" message disappear at first if the user tries searching for a new id
        setMinifigure2NotFound(false);
        handleSelection2();
    }, [searchValue2]);


    return ( 
        <div className="bg-black">
            <div className="container mx-auto my-2 md:my-10 xl:my-20 flex flex-col gap-5 md:gap-8">

                <div className="flex justify-center items-center">
                    <div className="text-2xl md:text-3xl font-bold text-center">Select Two Minifigures to Compare</div>
                </div>

                <div className="h-[50vh] flex gap-4 md:gap-10 justify-center items-start">

                    <div className="h-full w-20 hidden md:flex flex-col gap-2 overflow-auto scroll2 ">
                        {themeMinifigures1.map((minifigure, i) => (
                            <div key={i} className="h-16 w-16 cursor-pointer hover:bg-slate-900">
                                {loadedImages1.includes(minifigure.minifig_id) ? (
                                <img
                                    className="h-full w-full object-contain"
                                    src={`${minifigureImagePath}/${minifigure.minifig_id}.png`}
                                    alt={minifigure.minifig_id}
                                    onClick={() => {setSearchValue1(minifigure.minifig_id)}}
                                /> ) : (
                                <div className="flex justify-center items-center">
                                    <ThemeMinifigurePlaceholder/>
                                </div>
                                )}
                            </div> 
                        ))}
                    </div>

                    <div className="flex-1 h-full" id="minifigure-1">
                        <div className="flex flex-col gap-2 justify-center items-center">
                            <div className="flex justify-center items-center">
                                <input className=" bg-[#1e1e1e] rounded-md placeholder-gray-200 placeholder-opacity-50 w-32 md:w-56 h-6 text-center"
                                    type="text"
                                    placeholder="Enter Minifig No."
                                    value={searchValue1}
                                    onChange={(e) => setSearchValue1(e.target.value.toLowerCase())}
                                />
                            </div>

                            <div className="flex justify-center items-start h-6 w-full">
                                {minifigure1NotFound === true ? (
                                    <p className="text-red-500">Enter a valid ID</p>
                                ) : (
                                    <p></p>
                                )}
                            </div>

                            <div className="w-full p-2 flex flex-col gap-5 justify-center items-center">
                                {minifigure1 && minifigure1.length > 0 ? (
                                <div className="flex flex-col gap-1 justify-center items-center">
                                    <img className="h-60 md:h-80 object-contain" src={`${minifigureImagePath}/${minifigure1[0].minifig_id}.png`} alt={minifigure1[0].minifig_id}/>
                                    <div className="flex flex-col flex-wrap justify-center items-center">
                                        <p className="md:h-6 text-center"><strong className="text-green-500">Name:</strong> {minifigure1[0].character_name}</p>
                                        <p className="md:h-6 text-center"><strong className="text-green-500">Rating:</strong> {Math.round(minifigure1[0].rating)}</p>
                                    </div>
                                </div>
                                ) : (
                                <div className="flex flex-col gap-1 justify-center items-center">
                                    <CompareMinifigurePlaceholder/>
                                    <div className="flex flex-col justify-center items-center">
                                        <p className="h-6"></p>
                                        <p className="h-6"></p>
                                    </div>
                                </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="flex-1 h-full" id="minifigure-2">
                        <div className="flex flex-col gap-2 justify-center items-center">
                            <div className="flex justify-center items-center">
                                <input className=" bg-[#1e1e1e] rounded-md placeholder-gray-200 placeholder-opacity-50 w-32 md:w-56 h-6 text-center"
                                    type="text"
                                    placeholder="Enter Minifig No."
                                    value={searchValue2}
                                    onChange={(e) => setSearchValue2(e.target.value.toLowerCase())}
                                />
                            </div>

                            <div className="flex justify-center items-start h-6 w-full">
                                {minifigure2NotFound === true ? (
                                    <p className="text-red-500">Enter a valid ID</p>
                                ) : (
                                    <p></p>
                                )}
                            </div>

                            <div className="w-full p-2 flex flex-col gap-5 justify-center items-center">
                                {minifigure2 && minifigure2.length > 0 ? (
                                <div className="flex flex-col gap-1 justify-center items-center">
                                    <img className="h-60 md:h-80 object-contain" src={`${minifigureImagePath}/${minifigure2[0].minifig_id}.png`} alt={minifigure2[0].minifig_id}/>
                                    <div className="flex flex-col justify-center items-center">
                                        <p className="md:h-6 text-center"><strong className="text-green-500">Name:</strong> {minifigure2[0].character_name}</p>
                                        <p className="md:h-6 text-center"><strong className="text-green-500">Rating:</strong> {Math.round(minifigure2[0].rating)}</p>
                                    </div>
                                </div>
                                ) : (
                                <div className="flex flex-col gap-1 justify-center items-center">
                                    <CompareMinifigurePlaceholder/>
                                    <div className="flex flex-col justify-center items-center">
                                        <p className="h-6"></p>
                                        <p className="h-6"></p>
                                    </div>
                                </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className=" h-full w-20 hidden md:flex flex-col gap-2 overflow-auto scroll2">
                        {themeMinifigures2.map((minifigure, i) => (
                            <div key={i} className="h-16 w-16 cursor-pointer hover:bg-slate-900">
                                {loadedImages2.includes(minifigure.minifig_id) ? (
                                <img
                                    className="h-full w-full object-contain"
                                    src={`${minifigureImagePath}/${minifigure.minifig_id}.png`}
                                    alt={minifigure.minifig_id}
                                    onClick={() => {setSearchValue2(minifigure.minifig_id)}}
                                /> ) : (
                                <div className="flex justify-center items-center">
                                    <ThemeMinifigurePlaceholder/>
                                </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex justify-center items-center h-20">
                    {headToHeadData && !loading ? (
                    <div className="flex flex-col justify-center items-center gap-2">
                        <h1 className="text-2xl font-bold">Head to Head</h1>
                        <div className="flex gap-14">
                            <p className="text-2xl font-bold">{headToHeadData.minifig1Wins}</p>
                            <p className="text-2xl font-bold">-</p>
                            <p className="text-2xl font-bold">{headToHeadData.minifig2Wins}</p>
                        </div>
                    </div>
                    ) : headToHeadData && loading === true && searchValue1.length >= 5 && searchValue2.length >= 5 ? (
                    <div>
                        <p className="text-center text-lg">Running comparison...</p>
                    </div>
                    ) : !headToHeadData && loading === true && searchValue1.length >= 5 && searchValue2.length >= 5 ? (
                    <div>
                        <p className="text-center text-lg">Running comparison...</p>
                    </div>
                    ) : !headToHeadData && comparisonRun === true ? (
                    <div>
                        <p className="text-lg text-center">These two minifigures haven't been compared yet</p>
                    </div>
                    ) : (
                    <div>
                        <p className="text-lg text-center">Select two minifigures to view their head-to-head</p>
                    </div>
                    )}
                </div>
            </div>
        </div>
     );
}
 
export default CompareLayout;