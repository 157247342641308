import { useParams, NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import RatingsChart from "../components/RatingsChart";

const MinifigurePage = () => {
  const {searchValue, themeUrl} = useParams(); // get the search value (i.e. minifig ID) from the url. The url is specified in the SearchBar component. Also get the themeUrl for use in PHP scripts

  const [winLossStats, setWinLossStats] = useState({});
  const [totalMatchUps, setTotalMatchUps] = useState(null);
  const [winPercentage, setWinPercentage] = useState(0);
  const [position, setPosition] = useState(null);
  const [positionInTheme, setPositionInTheme] = useState(null);
  const [positionChange, setPositionChange] = useState("");
  const [positionChangeInTheme, setPositionChangeInTheme] = useState("");
  const [daysInTop100, setDaysInTop100] = useState("");
  const [themeName, setThemeName] = useState("");
  const [currentRating, setCurrentRating] = useState("");
  const [productionQty, setProductionQty] = useState("");
  const [geographicalRelease, setGeographicalRelease] = useState("");
  const [description, setDescription] = useState("");
  const [characterName, setCharacterName] = useState("");
  const [characterMinifigureCount, setCharacterMinifigureCount] = useState("");
  const [ratingsData, setRatingsData] = useState([]); //Set to expect an array because the associated PHP script returns an array of objects
  const [fetchError, setFetchError] = useState(null);

  const minifigureImagePath = "/images/minifigures";

  // Determine the API base URL based on the environment
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
    
  const brickrankerURL = "https://brickranker.com/rankings/minifigures"

  const fetchMinifigureData = async () => {
    try {
      // Fetch ratings for graph
      const ratingResponse = await fetch(
        `${apiUrl}/getDailyRatings.php?minifigID=${searchValue}`
      );
      const ratingData = await ratingResponse.json();
      setRatingsData(ratingData);
    } catch (error) {
      console.error("Error fetching daily rating:", error);
    }

    try {
      // Fetch ratings for graph
      const positionChangeResponse = await fetch(
        `${apiUrl}/getPositionChange.php?minifigID=${searchValue}`
      );
      const positionChangeData = await positionChangeResponse.json();
      setPositionChange(positionChangeData);
    } catch (error) {
      console.error("Error fetching position change:", error);
    }

    try {
      // Fetch ratings for graph
      const positionChangeInThemeResponse = await fetch(
        `${apiUrl}/getPositionChangeInTheme.php?minifigID=${searchValue}&themeUrl=${themeUrl}`
      );
      const positionChangeInThemeData = await positionChangeInThemeResponse.json();
      setPositionChangeInTheme(positionChangeInThemeData);
    } catch (error) {
      console.error("Error fetching position change in theme:", error);
    }

    try {
      // Fetch ratings for graph
      const daysInTop100Response = await fetch(
        `${apiUrl}/getDaysInTop100.php?minifigID=${searchValue}`
      );
      const daysInTop100Data = await daysInTop100Response.json();
      setDaysInTop100(daysInTop100Data);
    } catch (error) {
      console.error("Error fetching position change:", error);
    }

    try {
      const minifigureDetailsResponse = await fetch(
        `${apiUrl}/getMinifigureDetails.php?minifigID=${searchValue}`
      );
      const minifigureDetailsData = await minifigureDetailsResponse.json();
      const { theme_url, theme, character_name, rating, production_qty, geographical_release, description } = minifigureDetailsData.data;
      const themeUrl = theme_url;
      //const themeName = minifigureDetailsData.themeName;
      const characterName = minifigureDetailsData.data.character_name;
      setThemeName(theme);
      setCharacterName(character_name);
      setCurrentRating(rating);
      setProductionQty(production_qty);
      setGeographicalRelease(geographical_release);
      setDescription(description);
      
      // To ensure that the themeUrl fetched from the first API is available to the getMinifigurePosition API, the try loop for the getMinifigurePosition API is within the first try loop (i.e. the one for the getMinifigureThemeUrl API).
      // We need to do this because API calls in JavaScript are asynchronous, meaning they don't wait for something else to finish before executing. By placing the second API call inside the first try loop, you ensure that they are executed only after the themeUrl is successfully fetched. 
      try {
        // Fetch the minifigure's position (both in total and within its theme)
        const positionResponse = await fetch(
          `${apiUrl}/getMinifigurePosition.php?minifigID=${searchValue}&themeUrl=${themeUrl}`
        );
        const positionData = await positionResponse.json();
        setPosition(positionData.position);
        setPositionInTheme(positionData.positionWithinTheme);
      } catch (error) {
        console.error("Error fetching minifigure positions:", error);
      }

      try {
        // Fetch the minifigure's win/loss statistics
        const statsResponse = await fetch(
          `${apiUrl}/getMinifigureStats.php?minifigID=${searchValue}`
        );
        const statsData = await statsResponse.json();
        setWinLossStats(statsData);
        setTotalMatchUps(statsData.totalWins + statsData.totalLosses);
      
        // Calculate win percentage using the updated totalMatchUps
        if (statsData.totalWins !== 0) {
          const percentage = (statsData.totalWins / (statsData.totalWins + statsData.totalLosses)) * 100;
          setWinPercentage(percentage.toFixed(2)); // Set winPercentage to two decimal places
        } else { //If the minifigure doesn't have any wins the setWinPercentage is set to 0. This actually isn't needed as the winPercentage state variable is set to 0 by default.
          setWinPercentage(0);
        }

      } catch (error) {
        console.error("Error fetching win and loss statistics:", error);
      }

      try {
        // Fetch the number of minifigures of the character
        const characterMinifigureCountResponse = await fetch(
          `${apiUrl}/getCountOfCharacterMinifigures.php?characterName=${characterName}`
        );
        const characterMinifigureCountData = await characterMinifigureCountResponse.json();
        setCharacterMinifigureCount(characterMinifigureCountData);
      } catch (error) {
        console.error("Error fetching number of minifigures for character:", error);
      }

    } catch (error) {
      console.error("Error fetching minifigure details:", error);
      setFetchError(true)
    }
  };

  // Access the values returned by getPositionChange script
  const currentPosition = positionChange.currentPosition;
  const previousPosition = positionChange.previousPosition;
  const positionWeekAgo = positionChange.positionWeekAgo;
  const dailyChange = currentPosition - previousPosition;

  // Access the values returned by getPositionChange script
  const currentPositionInTheme = positionChangeInTheme.currentPosition;
  const previousPositionInTheme = positionChangeInTheme.previousPosition;
  const positionWeekAgoInTheme = positionChangeInTheme.positionWeekAgo;
  const dailyChangeInTheme = currentPositionInTheme - previousPositionInTheme;

  useEffect(() => {
      fetchMinifigureData();
  }, [searchValue]); // Call the effect whenever the minifigID changes
  
  if(!fetchError) {
  return (
    <div className="flex flex-col justify-center items-center p-2 md:p-10">
      
        <div className="container flex flex-col lg:flex-row gap-5 xl:gap-10 px-1 md:px-5 xl:px-20 py-5 justify-center items-stretch">

            <div className="grow basis-0 flex justify-center items-center">
                      <img className="h-60 md:h-80 lg:h-96 object-contain" src={`${minifigureImagePath}/${searchValue}.png`} alt={searchValue}/>
            </div>

            <div className="grow basis-0">

                <div className="border-b border-white border-opacity-50 p-3">
                  <h2 className="text-center font-bold">{characterName} ({searchValue})</h2>
                </div>

                <div className="border-b border-white border-opacity-50">
                  <h2 className="bg-[#0A0A0A] p-2 font-bold">Rating</h2>
                  {currentRating !== null && <p className="px-2 py-1">Current Rating: <span className="font-bold text-green-500">{Math.round(currentRating)}</span></p>}
                </div>

                <div className="border-b border-white border-opacity-50">
                  <h2 className="bg-[#0A0A0A] p-2 font-bold">Rankings</h2>
                  <div className="flex justify-start items-center gap-1">
                    {position !== null && 
                      <p className="px-2 py-1 flex gap-1 items-center">
                        {position === 1 ? 
                        <svg height="20" viewBox="0 0 441 430" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M295 200.5H147L295 0H441L295 200.5Z" fill="#3EEB3B"/>
                          <path d="M148 0H0L148 200.5H294L148 0Z" fill="#86FF8B"/>
                          <circle cx="224.5" cy="295.5" r="134.5" fill="#EED15C"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M224 399C281.438 399 328 352.438 328 295C328 237.562 281.438 191 224 191C166.562 191 120 237.562 120 295C120 352.438 166.562 399 224 399ZM224 375C268.183 375 304 339.183 304 295C304 250.817 268.183 215 224 215C179.817 215 144 250.817 144 295C144 339.183 179.817 375 224 375Z" fill="#D9BB44"/>
                        </svg>
                        : position === 2 ? 
                        <svg height="20" viewBox="0 0 441 430" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M295 200.5H147L295 0H441L295 200.5Z" fill="#3EEB3B"/>
                          <path d="M148 0H0L148 200.5H294L148 0Z" fill="#86FF8B"/>
                          <circle cx="224.5" cy="295.5" r="134.5" fill="#D9D9D9"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M224 399C281.438 399 328 352.438 328 295C328 237.562 281.438 191 224 191C166.562 191 120 237.562 120 295C120 352.438 166.562 399 224 399ZM224 375C268.183 375 304 339.183 304 295C304 250.817 268.183 215 224 215C179.817 215 144 250.817 144 295C144 339.183 179.817 375 224 375Z" fill="#B9B7B7"/>
                        </svg>
                        : position === 3 ?
                        <svg height="20" viewBox="0 0 441 430" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M295 200.5H147L295 0H441L295 200.5Z" fill="#3EEB3B"/>
                          <path d="M148 0H0L148 200.5H294L148 0Z" fill="#86FF8B"/>
                          <circle cx="224.5" cy="295.5" r="134.5" fill="#D7961A"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M224 399C281.438 399 328 352.438 328 295C328 237.562 281.438 191 224 191C166.562 191 120 237.562 120 295C120 352.438 166.562 399 224 399ZM224 375C268.183 375 304 339.183 304 295C304 250.817 268.183 215 224 215C179.817 215 144 250.817 144 295C144 339.183 179.817 375 224 375Z" fill="#B7872B"/>
                        </svg>
                        : null} 
                        #{position} Highest Rated Minifigure
                      </p>
                    }
                    {currentPosition < previousPosition ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#4ade80"
                        className="w-6 h-6"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                      </svg>
                    ) : (
                      previousPosition < currentPosition && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="#f87171"
                          className="w-6 h-6"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                        </svg>
                      )
                    )}
                    {dailyChange > 0 ? (<p className="text-red-400">{dailyChange}</p>) : dailyChange < 0 ? (<p className="text-green-400">{Math.abs(dailyChange)}</p>) : (<p></p>)}
                  </div>
                  <div className="flex justify-start items-center gap-1">
                    {positionInTheme !== null && 
                      <p className="px-2 pt-1 pb-2 flex gap-1 items-center">{positionInTheme === 1 ? 
                        <svg height="20" viewBox="0 0 441 430" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M295 200.5H147L295 0H441L295 200.5Z" fill="#FB6767"/>
                          <path d="M148 0H0L148 200.5H294L148 0Z" fill="#FD9E9E"/>
                          <circle cx="224.5" cy="295.5" r="134.5" fill="#EED15C"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M224 399C281.438 399 328 352.438 328 295C328 237.562 281.438 191 224 191C166.562 191 120 237.562 120 295C120 352.438 166.562 399 224 399ZM224 375C268.183 375 304 339.183 304 295C304 250.817 268.183 215 224 215C179.817 215 144 250.817 144 295C144 339.183 179.817 375 224 375Z" fill="#D9BB44"/>
                        </svg>
                        : positionInTheme === 2 ? 
                        <svg height="20" viewBox="0 0 441 430" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M295 200.5H147L295 0H441L295 200.5Z" fill="#FB6767"/>
                          <path d="M148 0H0L148 200.5H294L148 0Z" fill="#FD9E9E"/>
                          <circle cx="224.5" cy="295.5" r="134.5" fill="#D9D9D9"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M224 399C281.438 399 328 352.438 328 295C328 237.562 281.438 191 224 191C166.562 191 120 237.562 120 295C120 352.438 166.562 399 224 399ZM224 375C268.183 375 304 339.183 304 295C304 250.817 268.183 215 224 215C179.817 215 144 250.817 144 295C144 339.183 179.817 375 224 375Z" fill="#B9B7B7"/>
                        </svg>
                        : positionInTheme === 3 ?
                        <svg height="20" viewBox="0 0 441 430" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M295 200.5H147L295 0H441L295 200.5Z" fill="#FB6767"/>
                          <path d="M148 0H0L148 200.5H294L148 0Z" fill="#FD9E9E"/>
                          <circle cx="224.5" cy="295.5" r="134.5" fill="#D7961A"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M224 399C281.438 399 328 352.438 328 295C328 237.562 281.438 191 224 191C166.562 191 120 237.562 120 295C120 352.438 166.562 399 224 399ZM224 375C268.183 375 304 339.183 304 295C304 250.817 268.183 215 224 215C179.817 215 144 250.817 144 295C144 339.183 179.817 375 224 375Z" fill="#B7872B"/>
                        </svg>
                        : null} 
                        #{positionInTheme} Highest Rated {themeName !== 'Collectible Minifigures' ? <span>{themeName} Minifigure</span> : <span>Collectible Minifigure</span>}
                      </p>}
                    {currentPositionInTheme < previousPositionInTheme ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#4ade80"
                        className="w-6 h-6"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                      </svg>
                    ) : (
                    previousPositionInTheme < currentPositionInTheme && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#f87171"
                        className="w-6 h-6"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                      </svg>
                    )
                    )}
                    {dailyChangeInTheme > 0 ? (<p className="text-red-400">{dailyChangeInTheme}</p>) : dailyChangeInTheme < 0 ? (<p className="text-green-400">{Math.abs(dailyChangeInTheme)}</p>) : (<p></p>)}
                  </div>
                </div>

                <div className="border-b border-white border-opacity-50">
                  <h2 className="bg-[#0A0A0A] p-2 font-bold">Time Spent in Top 100</h2>
                  <p className="px-2 py-1">Current Streak: {daysInTop100.consecutiveDays} {daysInTop100.consecutiveDays === 1 ? <span>Day</span> : <span>Days</span>}</p>
                  <p className="px-2 py-1">Total Time: {daysInTop100.totalDays} {daysInTop100.totalDays === 1 ? <span>Day</span> : <span>Days</span>} ({Math.round((daysInTop100.totalDays / daysInTop100.maximumDays) * 100)}%)</p>
                </div>

                <div className="border-b border-white border-opacity-50">
                  <h2 className="bg-[#0A0A0A] p-2 font-bold">Match Up Record</h2>
                  <p className="px-2 py-1">Matchups Won: {winLossStats.totalWins}</p>
                  <p className="px-2 py-1">Matchups Lost: {winLossStats.totalLosses}</p>
                  <p className="px-2 pt-1 pb-2">Total Matchups: {totalMatchUps}</p>
                  <p className="px-2 pt-1 pb-2">Win Percentage: {winPercentage}%</p>
                </div>
            </div>

            <div className="grow basis-0 flex flex-col gap-16 justify-center">
              <div>
              <div className="flex gap-2 items-center p-2 mr-20">
                <div className="grow basis-0 flex justify-center">
                  <img className="" src="brickranker-logo-small.webp" alt="Brick Ranker logo"/>
                </div>
                <div className="grow basis-0">
                  <p>Brick Ranker</p>
                </div>
                <div className="grow basis-0">
                  <a href={`${brickrankerURL}/${searchValue}`} className="inline-block w-full text-center text-black font-bold bg-green-500 p-1 rounded-md">View Value</a>
                </div>
              </div>

              <div className="flex gap-2 items-center p-2 mr-20">
                <div className="grow basis-0 flex justify-center">
                  <img className="" src="bricklink-logo-small.png" alt="Bricklink logo"/>
                </div>
                <div className="grow basis-0">
                  <p>Bricklink</p>
                </div>
                <div className="grow basis-0">
                  <a href={`https://www.bricklink.com/v2/catalog/catalogitem.page?M=${searchValue}#T=S&O={%22iconly%22:0}`} className="inline-block w-full text-center text-black font-bold bg-green-500 p-1 rounded-md">Buy</a>
                </div>
              </div>
              </div>

              <div className="text-center text-xl font-bold">
                <h1 className="py-2">Minifigures of this Character</h1>
                <NavLink className="text-3xl font-bold text-center text-green-500 hover:text-green-400" to={`/results/minifigures/${characterName}`}>{characterMinifigureCount}</NavLink> {/* Having the / before results is important as this make the path an absolute path. If this wasn't there it would be relative and the path specified in the NavLink would get added to the path of the MinifigurePage component*/}
              </div>

            </div>
        
        </div>

        {description && (
        <div className="container flex flex-col lg:flex-row gap-10 lg:gap-5 xl:gap-10 px-1 md:px-5 xl:px-20 py-5 justify-center items-stretch">
          <div className="basis-4/6">
            <h1 className="text-xl font-bold py-2">Description</h1>
            <p>{description}</p>
          </div>
          <div className="basis-1/6 text-center">
            <h1 className="text-xl font-bold py-2">Production Quantity</h1>
            <p className="text-3xl font-bold text-green-500">{productionQty}</p>
          </div>
          <div className="basis-1/6 text-center">
            <h1 className="text-xl font-bold py-2">Geographical Release</h1>
            <p className="text-3xl font-bold text-green-500">{geographicalRelease}</p>
          </div>
        </div>
        )}

        <div className="container flex flex-col px-1 md:px-5 xl:px-20 justify-center">
          <div className="grow basis-0">
            <div className="border-t border-b border-white border-opacity-50 flex justify-center items-center">
                <RatingsChart chartData={ratingsData}/>
            </div>
          </div>
        </div>

    </div>
  );
  }
  else {
    return (
      <div className="flex justify-center items-center p-10">
        <div className="container flex flex-col gap-5 justify-center items-center">
          <h1 className="text-xl">Oh no!</h1>
          <p className="leading-6">The LEGO character with the code <span className="font-bold">{searchValue}</span> can't be found in the database. This is either because it doesn't exist or is a variant of another minifigure. It might also be that the LEGO character you are thinking of does not contain at least two of the three standard minfigure parts: head, torso and legs. Any LEGO character that doesn't include at least two of those parts isn't officially classed as a minifigure. </p>
          <p>Please try searching for a different minfigure.</p>
        </div>
      </div>
    )
  }
}
 
export default MinifigurePage;