import {useNavigate} from "react-router-dom";
import { useState, useEffect, useRef } from "react";

const SearchBar = () => {
    const navigate = useNavigate();

    // State variables
    const [searchValue, setSearchValue] = useState("");
    const [suggestions, setSuggestions] = useState([]);

    // Reference variables
    const suggestionsRef = useRef(null);

    // Determine the API base URL based on the environment
    const apiUrl = process.env.REACT_APP_API_BASE_URL;

    // function for checking if the value entered into the search bar contains three consecutive digits and therefore a minifigure id has been entered
    // the function returns either true or false
    const isMinifigID = (value) => /[0-9]{3,}/.test(value);

    const handleSearch = async () => {
      try {
        const formattedSearchValue = searchValue.trim().toLowerCase();
        const isID = isMinifigID(formattedSearchValue);
    
        // if isID is true (i.e. the search value is a minifigure ID), the PHP script will be run. This script returns the theme_url needed to construct the path to the minifigure's page
        if (isID) {
          const url = `${apiUrl}/getMinifigureThemeUrl.php?minifigID=${formattedSearchValue}`;
    
          const minifigureThemeUrlResponse = await fetch(url);
          const minifigureThemeUrlData = await minifigureThemeUrlResponse.json();
    
          if (minifigureThemeUrlData.minifigures.length > 0) {
            const themeUrl = minifigureThemeUrlData.minifigures[0].theme_url;
            navigate(`/results/${themeUrl}/minifigures/${formattedSearchValue}`);
          } else {
            // Handle case where no minifigure is found
            console.log("No matching minifigure found.");
          }
        } else {
          // Handle case where a character name is entered. We don't need to run a PHP script for constructing the path to the character page, as the path only includes the entered search value
          navigate(`/results/minifigures/${formattedSearchValue}`);
        }
      } catch (error) {
        console.error("Error fetching minifigure(s):", error);
      }
    };

    // function for checking if the enter key has been pressed. If so, the handleSearch function is run
    const handleKeyPress = (event) => {
      // Check if the pressed key is "Enter"
      if (event.key === 'Enter') {
        // Prevent the default form submission behavior
        event.preventDefault();
        
        // Trigger the search function
        handleSearch();
      }
    };

    const handleInputChange = async (input) => {
      setSearchValue(input);

      // Only get search suggestions if at least 3 characters have been entered into the search bar
      if (input.length >= 3) {
        try {
          const response = await fetch(`${apiUrl}/getSearchSuggestions.php?input=${input}`);
          const data = await response.json();
    
          setSuggestions(data.suggestions);
        } catch (error) {
          console.error("Error fetching autocomplete suggestions:", error);
        }
      } else {
        // If the input length is less than 3, clear the suggestions
        setSuggestions([]);
      }
    };

    useEffect(() => {
      console.log("Search Value: ", searchValue)
    },[searchValue])

    const handleSuggestionClick = (item) => {
      setSearchValue(item)
      setSuggestions([]);
      //handleSearch();
    }

    const handleDocumentClick = (event) => {
      // Check if the clicked element is outside the suggestions box
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
        setSuggestions([]);
      }
    };

    useEffect(() => {
      // Add event listener to document when the component mounts
      document.addEventListener("click", handleDocumentClick);
    
      // Remove event listener when the component unmounts
      return () => {
        document.removeEventListener("click", handleDocumentClick);
      };
    }, []); // Empty dependency array ensures the effect runs only once when the component mounts

    return ( 
        <div className="border-2 rounded-md text-center flex">
        
            <input className=" bg-[#1e1e1e] rounded-l-md placeholder-gray-200 placeholder-opacity-50 w-52 md:w-64 h-full text-center"
                type="text"
                placeholder="Search by Minifig Name / No."
                value={searchValue}
                onChange={(e) => handleInputChange(e.target.value.toLowerCase())}
                onKeyPress={handleKeyPress}
            />
            {suggestions.length > 0 && (
              <div ref={suggestionsRef} className="absolute z-10 bg-[#1e1e1e] mt-8 p-2 rounded-md h-40 overflow-auto">
                {suggestions.map((item) => (
                  <div key={item} className="cursor-pointer hover:bg-black" onClick={() => handleSuggestionClick(item)}>
                    {item}
                  </div>
                ))}
              </div>
            )}
            <button className="bg-green-500 rounded-r-md w-12 md:w-32 m-0" onClick={handleSearch}>
                <span className="hidden md:inline-block">Search</span>
                <svg className="w-6 inline-block md:hidden" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                </svg>
            </button>
            
        </div>
     );
}
 
export default SearchBar;