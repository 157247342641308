import { NavLink, Outlet, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

const RootLayout = () => {
    const [isMenuClicked, setIsMenuClicked] = useState(false);

    const location = useLocation();

    const handleMenuClick = () => {
        setIsMenuClicked(!isMenuClicked);
    };

    // By passing the location object as a dependency into the useEffect hook, the isMenuClicked will be set to false whenever the route changes (i.e. when a user navigates to a different page after selecting an option from the dropdown menu)
    // The location object contains information about the current URL, including the pathname, search parameters, hash, and more.
    useEffect(() => {
        setIsMenuClicked(false);
    }, [location]);

    return ( 
        <div className="relative bg-black text-white h-screen">
            <header className="border-b border-white border-opacity-10 h-14 md:h-20 top-0 sticky backdrop-blur-md z-10">
                <nav className={`flex justify-between items-center py-2 md:py-5 px-2 md:px-8 lg:px-12 ${isMenuClicked ? 'flex-col' : ''}`}>
                    <div className="flex justify-between w-full md:w-auto">
                        <h2 className="rounded-md text-2xl">Brick<span className="text-green-500">elo</span></h2>
                        <svg className={`w-10 block md:hidden cursor-pointer transition ease-out duration-300 ${isMenuClicked ? 'transform rotate-90' : ''}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" onClick={handleMenuClick}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>
                    </div>


                    <div className={`md:flex gap-10 text-lg ${isMenuClicked ? 'flex flex-col gap-3 text-sm md:text-lg absolute z-10 top-full right-12 bg-black bg-opacity-70 p-4' : 'hidden relative'}`}>
                        <NavLink className="bg-black text-white border-2 border-white text-center p-1 rounded-md" to="/">Home</NavLink>
                        <NavLink className="bg-black text-white border-2 border-white text-center p-1 rounded-md" to="ranking">Rank Minifigures</NavLink>
                        <NavLink className="bg-black text-white border-2 border-white text-center p-1 rounded-md" to="results">Top 100</NavLink>
                        <NavLink className="bg-black text-white border-2 border-white text-center p-1 rounded-md" to="compare">Compare</NavLink>
                        <NavLink className="bg-black text-white border-2 border-white text-center p-1 rounded-md" to="blog">Blog</NavLink>
                    </div>
                </nav>
            </header>

            <main className="bg-black">
                <Outlet/>
            </main>

            {location.pathname === '/' && (
            <footer className={`bg-black h-14 md:h-20 w-full flex gap-12 justify-center items-center border-t border-white border-opacity-10 sticky bottom-0`}>
                <a href="https://twitter.com/Brickelo_" className="hover:text-green-500 duration-300">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-twitter"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>
                </a>
                <a href="https://twitter.com/Brickelo_" className="hover:text-green-500 duration-300">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-instagram"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg>
                </a>
                <a href="mailto:graham.pattle@gmail.com" className="hover:text-green-500 duration-300">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                </a>
            </footer>
            )}
        </div>
     );
}
 
export default RootLayout;