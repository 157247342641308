import { useParams, useMatch, Outlet, useResolvedPath, NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import MinifigurePlaceholder from "../components/MinifigurePlaceholder";

const ResultsPage = ({themesData}) => {
    const {themeUrl} = useParams();
    //Finds the theme_name associated with the themeUrl in the path of the RankingPage component. This is used to filter the minifigures data recieved from the minifigureLoader to only show minifigures in the theme that has been clicked.
    const theme = themesData.find((themeData) => themeData.url === themeUrl);
    const themeName = theme ? theme.theme_name : "";

    const url = useResolvedPath("").pathname;
    
    const match = useMatch('/results/:themeUrl');

    //Gets the path of where the minifigure images are saved as stores them in the minifigureImagePath variable
    const minifigureImagePath = "/images/minifigures";

    const [minifigureByThemeData, setMinifigureByThemeData] = useState([]);
    const [loadedImages, setLoadedImages] = useState([]);
    
    // Determine the API base URL based on the environment
    const apiUrl = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        // Function to fetch themeUrl and win/loss statistics for the specific minifigure
        const fetchMinifigurebyThemeData = async () => {
          try {
            const response = await fetch(
              `${apiUrl}/getTopMinifiguresByTheme.php?themeUrl=${themeUrl}`
            );
            const data = await response.json();
            setMinifigureByThemeData(data);

            // Iterate through each minifigure and load its image
            data.forEach(minifigure => {
              const image = new Image();
              image.src = `${minifigureImagePath}/${minifigure.minifig_id}.png`;
              image.onload = () => {
                  // Update loadedImages with the newly loaded minifigure's ID
                  setLoadedImages(prevLoaded => [...prevLoaded, minifigure.minifig_id]);
              };
            });

          } catch (error) {
            //console.error("Error fetching themeUrl:", error);
          }
        };
    
        fetchMinifigurebyThemeData();
    }, [themeUrl]); // Call the effect whenever the themeUrl changes

    return ( 
        <div>
            {match && ( 
            <div className="container mx-auto text-sm md:text-md lg:text-lg">

                <div className="flex justify-center py-5">
                  <h1 className="text-2xl font-bold text-center">The 100 Highest Rated LEGO {themeName} Minifigures</h1>
                </div>

                <div className="flex gap-2 md:gap-10 border-b border-white border-opacity-50 p-3">
                  <p className="grow basis-0 text-center font-bold hidden md:block">24h</p>
                  <p className="grow basis-0 text-center font-bold hidden md:block">Position</p>
                  <p className="grow basis-0 text-center font-bold block md:hidden">#</p>
                  <p className="grow basis-0 text-center"></p>
                  <p className="grow-[4] basis-0 font-bold">Name</p> {/* You can set arbitary values of flex-grow by putting a value in square brackets. See: https://tailwindcss.com/docs/flex-grow*/}
                  <p className="grow basis-0 text-center font-bold">Rating</p>
                  <p className="grow basis-0"></p>
                </div>

                {minifigureByThemeData.map((minifigure) => (
                <div key={minifigure.id} className="flex gap-2 md:gap-10 border-b border-white border-opacity-20 p-3">
                  <div className="grow basis-0 flex justify-center items-center gap-1">
                    {minifigure.currentPosition < minifigure.previousPosition ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#4ade80"
                        className="w-6 h-6"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                      </svg>
                    ) : (
                    minifigure.previousPosition < minifigure.currentPosition && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#f87171"
                        className="w-6 h-6"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                      </svg>
                      )
                    )}
                    {
                      minifigure.currentPosition - minifigure.previousPosition > 0 ? (
                        <p className="text-red-400">{Math.abs(minifigure.previousPosition - minifigure.currentPosition)}</p>
                      ) : minifigure.currentPosition - minifigure.previousPosition < 0 ? (
                        <p className="text-green-400">{Math.abs(minifigure.currentPosition - minifigure.previousPosition)}</p>
                      ) : (
                        <p></p>
                      )
                    }
                  </div>


                  <div className="grow basis-0 flex justify-center items-center">
                  <p className="">
                    {minifigure.position === 1 ?
                      <svg height="30" viewBox="0 0 441 430" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M295 200.5H147L295 0H441L295 200.5Z" fill="#FB6767"/>
                        <path d="M148 0H0L148 200.5H294L148 0Z" fill="#FD9E9E"/>
                        <circle cx="224.5" cy="295.5" r="134.5" fill="#EED15C"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M224 399C281.438 399 328 352.438 328 295C328 237.562 281.438 191 224 191C166.562 191 120 237.562 120 295C120 352.438 166.562 399 224 399ZM224 375C268.183 375 304 339.183 304 295C304 250.817 268.183 215 224 215C179.817 215 144 250.817 144 295C144 339.183 179.817 375 224 375Z" fill="#D9BB44"/>
                      </svg>
                    : minifigure.position === 2 ?
                      <svg height="30" viewBox="0 0 441 430" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M295 200.5H147L295 0H441L295 200.5Z" fill="#FB6767"/>
                        <path d="M148 0H0L148 200.5H294L148 0Z" fill="#FD9E9E"/>
                        <circle cx="224.5" cy="295.5" r="134.5" fill="#D9D9D9"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M224 399C281.438 399 328 352.438 328 295C328 237.562 281.438 191 224 191C166.562 191 120 237.562 120 295C120 352.438 166.562 399 224 399ZM224 375C268.183 375 304 339.183 304 295C304 250.817 268.183 215 224 215C179.817 215 144 250.817 144 295C144 339.183 179.817 375 224 375Z" fill="#B9B7B7"/>
                      </svg>
                    : minifigure.position === 3 ?
                      <svg height="30" viewBox="0 0 441 430" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M295 200.5H147L295 0H441L295 200.5Z" fill="#FB6767"/>
                        <path d="M148 0H0L148 200.5H294L148 0Z" fill="#FD9E9E"/>
                        <circle cx="224.5" cy="295.5" r="134.5" fill="#D7961A"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M224 399C281.438 399 328 352.438 328 295C328 237.562 281.438 191 224 191C166.562 191 120 237.562 120 295C120 352.438 166.562 399 224 399ZM224 375C268.183 375 304 339.183 304 295C304 250.817 268.183 215 224 215C179.817 215 144 250.817 144 295C144 339.183 179.817 375 224 375Z" fill="#B7872B"/>
                      </svg>
                    : minifigure.position}
                  </p>
                  </div>
                  <div className="grow basis-0 flex justify-center items-center">
                    {loadedImages.includes(minifigure.minifig_id) ? (
                      <img
                        className="h-20 m-h-[20] object-contain"
                        src={`${minifigureImagePath}/${minifigure.minifig_id}.png`}
                        alt={minifigure.minifig_id}
                      />
                    ) : (
                      <MinifigurePlaceholder/>
                    )}
                  </div>
                  <div className="grow-[4] basis-0 flex items-center">
                    <p className="">{minifigure.character_name} <span className="hidden md:inline-block">({minifigure.minifig_id})</span></p>
                  </div>
                  <div className="grow basis-0 flex justify-center items-center">
                    <p className="">{Math.round(minifigure.rating)}</p>
                  </div>
                  <div className="grow basis-0 flex justify-center items-center">
                    <NavLink className="text-center bg-green-500 p-2 rounded-md" to={`${url}/minifigures/${minifigure.minifig_id}`}>View Stats</NavLink>
                  </div>
                </div>
                ))}

            </div>
            )}

            <Outlet />
        </div>
     );
}
 
export default ResultsPage;