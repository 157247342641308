import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

const ChartForBlog2 = () => {
    const labels = [
        1978, 1979, 1980, 1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990, 1991,
        1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003,
        2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015,
        2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024
    ];
    
    const data = [
        973.38, 969.06, 907.31, 1049.09, 996.01, 999.55, 1009.00, 1034.91, 1000.96,
        1049.22, 1040.16, 1044.94, 1034.73, 1045.46, 1036.78, 1037.58, 1019.40,
        1024.75, 1058.54, 988.91, 993.37, 918.99, 931.96, 974.89, 928.9, 993.96,
        978.06, 1005.89, 1016.02, 1024.26, 1010.99, 1028.03, 1046.26, 1040.38,
        1023.64, 1014.24, 995.76, 1021.62, 994.01, 988.54, 991.14, 979.99, 971.54,
        974.71, 990.67
    ];

    // Min and max values for normalization
    const minValue = Math.min(...data);
    const maxValue = Math.max(...data);

    // Function to calculate color on a red-yellow-green gradient
    const getColorForValue = (value) => {
        const normalizedValue = (value - minValue) / (maxValue - minValue);
        
        let red, green;

        if (normalizedValue <= 0.5) {
            // Red to yellow gradient
            red = 255;
            green = Math.floor(2 * normalizedValue * 255);
        } else {
            // Yellow to green gradient
            red = Math.floor((1 - 2 * (normalizedValue - 0.5)) * 255);
            green = 255;
        }
        
        return `rgba(${red}, ${green}, 0, 0.5)`;
    };

    // Generate background colors based on data values
    const backgroundColors = data.map(getColorForValue);

    const BarChart = () => {
        return {
            labels: labels,
            datasets: [
                {
                    data: data,
                    label: "",
                    backgroundColor: backgroundColors,
                    borderWidth: 1,
                    hoverBackgroundColor: backgroundColors, // Matches hover color to main color
                    hoverBorderColor: backgroundColors, // Matches border color to main color
                }
            ]
        };
    };

    const options = {
        plugins: {
            legend: {
                display: false, // Hides the legend at the top of the chart
            },
        },
        scales: {
            y: {
                title: {
                    display: false, // Set display to true to show the y-axis label
                    text: "Rating", // The label for the y-axis
                },
                ticks: {
                    color: 'white', // Set the text color of y-axis ticks
                },
                min: 800
            },
            x: {
                ticks: {
                    color: 'white', // Set the text color of x-axis ticks
                },
            }
        },
        indexAxis: 'x',
    };

    return ( 
        <div className='w-full flex flex-col justify-center items-center mb-2'>
            <h2 className="p-2 font-bold text-lg">Minfigure Average Rating By Year</h2>
            <div className='relative w-full p-0'>
                <Bar data={BarChart()} options={{...options, responsive: true}}/>
            </div>
        </div>
    );
}

export default ChartForBlog2;
