import { NavLink, Link, Outlet, useLoaderData, useResolvedPath, useMatch } from "react-router-dom";
import { useState, useEffect } from "react";
import { Tooltip } from 'react-tooltip'
import SearchBar from "../components/SearchBar";

const ResultsLayout = ({themesData}) => {
    //Get path of the ResultsLayout component (i.e. "/results")
    const url = useResolvedPath("").pathname;
    const apiUrl = process.env.REACT_APP_API_BASE_URL;

    //Gets the path of where the minifigure images are saved as stores them in the minifigureImagePath variable
    const minifigureImagePath = "/images/minifigures";

    // Explain useMatch here
    const match = useMatch('/results');

    // State variable for themes navigation banner
    const [priorityThemes, setPriorityThemes] = useState([]);
    const [select, setSelect] = useState(false);
    const [totalRatings, setTotalRatings] = useState("");

    //Stores the data received from the topMinifiguresLoader in the topMinifigureData variable. The api in the loader gets the top 250 minifigures for displaying on the screen
    const topMinifigureData = useLoaderData();

    useEffect(() => {
      const getPriorityThemes = () => {
        const themesWithPriorityOne = themesData.filter((theme) => theme.priority === '1');
        setPriorityThemes(themesWithPriorityOne);
      };
  
      if (themesData.length > 0) {
        getPriorityThemes();
      }
    }, [themesData]);

    const fetchRisingMinifigures = async () => {
      try {
        // Fetch ratings for graph
        const response = await fetch(
          `${apiUrl}/getRisingMinifigures.php`
        );
        const data = await response.json();
        //setRatingsData(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching daily rating:", error);
      }
    }

    const fetchTotalRatings = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/getTotalRatings.php`
        );
        const data = await response.json();
        setTotalRatings(data);
      } catch (error) {
        console.error("Error fetching total ratings", error);
      }
    }

    useEffect(() => {
      fetchTotalRatings();
    }, [])

  return (
    <div className="bg-black">

      <div className="container bg-black flex justify-center mx-auto py-5">
        <nav className="flex flex-wrap xl:flex-nowrap gap-2 xl:gap-4 justify-center xl:justify-start text-sm md:text-lg">
          <NavLink className="bg-black text-white border-2 border-white text-center p-1 rounded-md" to={`${url}`} end> {/* By default, NavLink applies the active class to the link that matches the current route, and it also applies it to all parent links in the hierarchy. For example, if a theme is selected, the All button will also be active because the path associated with the All button (i.e. "/results") is the parent route of the theme routes. To prevent this behaviour we can add end prop to the All Navlink*/}
            All
          </NavLink>
        {priorityThemes.map((theme) => (
          <NavLink className="bg-black text-white border-2 border-white text-center p-1 rounded-md" to={`${url}/${theme.url}`} key={theme.theme_name} >
            <p>{theme.theme_name}</p>
          </NavLink>
        ))}
        </nav>
      </div>

      <div className="flex justify-center">
        <SearchBar/>
      </div>

      {match && (  
        
      <div className="container mx-auto text-sm md:text-md lg:text-lg">

        <div className="flex flex-col gap-2 justify-center py-5">
          <h1 className="text-2xl font-bold text-center">The 100 Highest Rated LEGO Minifigures</h1>
          <p className="text-center">Based on <span className="font-bold text-green-500">{totalRatings.totalEntries}</span> user rankings</p>
        </div>

        {/*<div className="flex gap-6">
          <NavLink className={`border-2 rounded-lg flex gap-2 items-center px-2 py-1 hover:bg-white hover:text-black ${match ? 'active' : ''}`}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="#eab308" dataSlot="icon" className="w-8 h-8">
              <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 18.75h-9m9 0a3 3 0 0 1 3 3h-15a3 3 0 0 1 3-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 0 1-.982-3.172M9.497 14.25a7.454 7.454 0 0 0 .981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 0 0 7.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 0 0 2.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 0 1 2.916.52 6.003 6.003 0 0 1-5.395 4.972m0 0a6.726 6.726 0 0 1-2.749 1.35m0 0a6.772 6.772 0 0 1-3.044 0" />
            </svg>
            <p className="h-8">Top</p>
          </NavLink>

          <NavLink className="border-2 rounded-lg flex gap-2 items-center px-2 py-1 hover:bg-white hover:text-black focus:bg-white focus:text-black focus:ring focus:border-green-300 focus:animate-pulse" to={`${url}/rising`} data-tooltip-id="rising-tooltip" data-tooltip-content="This takes a while...">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="#84cc16" dataSlot="icon" className="w-8 h-8">
              <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941" />
            </svg>
            <p className="h-8">Rising</p>
          </NavLink>
        </div>

        <Tooltip id="rising-tooltip"/>*/}

        <div className="flex gap-2 md:gap-10 border-b border-white border-opacity-50 p-3">
          <p className="grow basis-0 text-center font-bold hidden md:block">24h</p>
          <p className="grow basis-0 text-center font-bold hidden md:block">Position</p>
          <p className="grow basis-0 text-center font-bold block md:hidden">#</p>
          <p className="grow basis-0 text-center"></p>
          <p className="grow-[4] basis-0 font-bold">Name</p>
          <p className="grow basis-0 text-center font-bold">Rating</p>
          <p className="grow basis-0"></p>
        </div>

        {topMinifigureData.map((minifigure) => (
          <div key={minifigure.id} className="flex gap-2 md:gap-10 border-b border-white border-opacity-20 p-3">
            <div className="grow basis-0 flex justify-center items-center gap-1">
              {minifigure.currentPosition < minifigure.previousPosition ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#4ade80"
                        className="w-6 h-6"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                      </svg>
                    ) : (
                      minifigure.previousPosition < minifigure.currentPosition && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="#f87171"
                          className="w-6 h-6"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                        </svg>
                      )
                    )}
                    {
                      minifigure.currentPosition - minifigure.previousPosition > 0 ? (
                        <p className="text-red-400">{Math.abs(minifigure.previousPosition - minifigure.currentPosition)}</p>
                      ) : minifigure.currentPosition - minifigure.previousPosition < 0 ? (
                        <p className="text-green-400">{Math.abs(minifigure.currentPosition - minifigure.previousPosition)}</p>
                      ) : (
                        <p></p>
                      )
                    }
            </div>
            <div className="grow basis-0 flex justify-center items-center">
              <p className="">
                {minifigure.position === 1 ?
                <svg height="30" viewBox="0 0 441 430" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M295 200.5H147L295 0H441L295 200.5Z" fill="#3EEB3B"/>
                  <path d="M148 0H0L148 200.5H294L148 0Z" fill="#86FF8B"/>
                  <circle cx="224.5" cy="295.5" r="134.5" fill="#EED15C"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M224 399C281.438 399 328 352.438 328 295C328 237.562 281.438 191 224 191C166.562 191 120 237.562 120 295C120 352.438 166.562 399 224 399ZM224 375C268.183 375 304 339.183 304 295C304 250.817 268.183 215 224 215C179.817 215 144 250.817 144 295C144 339.183 179.817 375 224 375Z" fill="#D9BB44"/>
                </svg>
                : minifigure.position === 2 ?
                <svg height="30" viewBox="0 0 441 430" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M295 200.5H147L295 0H441L295 200.5Z" fill="#3EEB3B"/>
                  <path d="M148 0H0L148 200.5H294L148 0Z" fill="#86FF8B"/>
                  <circle cx="224.5" cy="295.5" r="134.5" fill="#D9D9D9"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M224 399C281.438 399 328 352.438 328 295C328 237.562 281.438 191 224 191C166.562 191 120 237.562 120 295C120 352.438 166.562 399 224 399ZM224 375C268.183 375 304 339.183 304 295C304 250.817 268.183 215 224 215C179.817 215 144 250.817 144 295C144 339.183 179.817 375 224 375Z" fill="#B9B7B7"/>
                </svg>
                : minifigure.position === 3 ?
                <svg height="30" viewBox="0 0 441 430" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M295 200.5H147L295 0H441L295 200.5Z" fill="#3EEB3B"/>
                  <path d="M148 0H0L148 200.5H294L148 0Z" fill="#86FF8B"/>
                  <circle cx="224.5" cy="295.5" r="134.5" fill="#D7961A"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M224 399C281.438 399 328 352.438 328 295C328 237.562 281.438 191 224 191C166.562 191 120 237.562 120 295C120 352.438 166.562 399 224 399ZM224 375C268.183 375 304 339.183 304 295C304 250.817 268.183 215 224 215C179.817 215 144 250.817 144 295C144 339.183 179.817 375 224 375Z" fill="#B7872B"/>
                </svg>
                : minifigure.position}
              </p>
            </div>
            <div className="grow basis-0 flex justify-center items-center">
              <img className="h-20 m-h-[20] object-contain" src={`${minifigureImagePath}/${minifigure.minifig_id}.png`} alt={minifigure.minifig_id}/>
            </div>
            <div className="grow-[4] basis-0 flex items-center">
              <p className="">{minifigure.character_name} <span className="hidden md:inline-block">({minifigure.minifig_id})</span></p>
            </div>
            <div className="grow basis-0 flex justify-center items-center">
              <p className="">{Math.round(minifigure.rating)}</p>
            </div>
            <div className="grow basis-0 flex justify-center items-center">
              <NavLink className="text-center bg-green-500 p-2 rounded-md" to={`${url}/${minifigure.theme_url}/minifigures/${minifigure.minifig_id}`}>View Stats</NavLink>
            </div>
          </div>
        ))}

      </div>
      )}

      <Outlet />
    </div>
  );
};

export default ResultsLayout;

//loader function
export const topMinifiguresLoader = async () => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const response = await fetch(`${apiUrl}/getTopMinifigures.php`)
    return response.json()
}